import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import AddressPopup from "./AddressPopup";
import NumberPopup from "./NumberPopup";
import AltNumberPopup from "./AltNumberPopup";
import ProfilePicture from "./ProfilePicture";
import axios from "axios";

const AccountDetails = () => {
  const [stringValue, setStringValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [altNumberValue, setAltNumberValue] = useState("");
  const [numberCodeValue, setNumberCodeValue] = useState("");
  const [panCardValue, setPanCardValue] = useState("");
  const [altNumberCodeValue, setAltNumberCodeValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [addressValue, setAddressValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stringError, setStringError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [panCardError, setPanCardError] = useState("");
  const [altNumberError, setAltNumberError] = useState("");
  const [numberCodeError, setNumberCodeError] = useState("");
  const [altNumberCodeError, setAltNumberCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const handleIconClick = () => {
    setShowAddressPopup(true);
  };

  const handleCloseAddressPopup = () => {
    setShowAddressPopup(false);
  };

  const handleSaveAddress = (address, city, state, country, pincode) => {
    setAddressValue(`${address}, ${city}, ${state}, ${country}, ${pincode}, `);
    setShowAddressPopup(false);
    toast.success("Address saved successfully");
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  const [showNumberPopup, setShowNumberPopup] = useState(false);
  const [showAltNumberPopup, setShowAltNumberPopup] = useState(false);

  const handlePancardIconClick = () => {
    setIsEditable(true);
  };

  const handleNumberIconClick = () => {
    setShowNumberPopup(true);
  };
  const handleAltNumberIconClick = () => {
    setShowAltNumberPopup(true);
  };
  const handleCloseAltNumberPopup = () => {
    setShowAltNumberPopup(false);
    handleReload();
  };
  const handleCloseOtpPopup = () => {
    setShowNumberPopup(false);
    handleReload();
  };
  const handleEditClick = () => {
    setIsPopupOpen(true);
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Save the address data
    setIsPopupOpen(false);
  };

  const handleCancelClick = () => {
    setIsPopupOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };
  const handleStringChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 50) {
      setStringValue(value);
      setStringError("");
    } else {
      setStringError("Please Enter a valid name");
    }
  };
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailValue(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value.length <= 50 && emailRegex.test(value)) {
      setEmailValue(value);
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleNumberCodeChange = (event) => {
    const value = event.target.value;
    const regex = /^[\d+]*$/;

    if (regex.test(value) && value.length <= 3) {
      setNumberCodeValue(value);
      setNumberCodeError("");
    } else if (!regex.test(value)) {
      setNumberCodeError("Only numeric values are allowed.");
    } else {
      setNumberCodeError("The number must be exactly 2 digits.");
    }
  };
  const handleAltNumberCodeChange = (event) => {
    const value = event.target.value;
    const regex = /^[\d+]*$/;

    if (regex.test(value) && value.length <= 3) {
      setAltNumberCodeValue(value);
      setAltNumberCodeError("");
    } else if (!regex.test(value)) {
      setAltNumberCodeError("Only numeric values are allowed.");
    } else {
      setAltNumberCodeError("The number must be exactly 2 digits.");
    }
  };

  const handleNumberChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value) && value.length <= 10) {
      setNumberValue(value);
      setNumberError("");
    } else if (!regex.test(value)) {
      setNumberError("Only numeric values are allowed.");
    } else {
      setNumberError("The number must be exactly 10 digits.");
    }
  };
  const handleAltNumberChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value) && value.length <= 10) {
      setAltNumberValue(value);
      setAltNumberError("");
    } else if (!regex.test(value)) {
      setAltNumberError("Only numeric values are allowed.");
    } else {
      setAltNumberError("The number must be exactly 10 digits.");
    }
  };

  const handlePanCardChange = (event) => {
    const value = event.target.value.toUpperCase();
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (value.length <= 10) {
      setPanCardValue(value);

      if (value.length === 10) {
        if (panCardRegex.test(value)) {
          setPanCardError(""); // Valid PAN card format
        } else {
          setPanCardError("Invalid PAN card format. it should be in this Format: AAAAA1234A");
        }
      } else {
        setPanCardError(""); // Clear error for incomplete input
      }
    } else {
      setPanCardError("PAN card number must be exactly 10 characters & format: AAAAA1234A");
    }
  };

  const handleCountryChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 50) {
      setCountryValue(value);
      setCountryError("");
    } else {
      setCountryError("Name must be less than 50 characters");
    }
  };

  const handleAddressChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9 ',+-]*$/;

    if (regex.test(value) && value.length <= 200) {
      setAddressValue(value);
      setAddressError("");
    } else if (!regex.test(value)) {
      setAddressError("Only alphanumeric values are allowed.");
    } else {
      setAddressError("The input must be exactly 200 characters.");
    }
  };
  //--------- API integration ---------\
  useEffect(() => {
    const getUserInfo = async () => {
      const token = sessionStorage.getItem('Token');
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
        const userData = response.data.data[0];

        // Log the API response
        console.log("API response data:", userData);

        setStringValue(userData.full_name || "");
        setEmailValue(userData.email || "");
        setNumberCodeValue(userData.country_code || "");
        setNumberValue(userData.mobile || "");
        setAltNumberCodeValue(userData.alt_country_code || "");
        setAltNumberValue(userData.alt_phone || "");
        setPanCardValue(userData.pan_number || "");
        setCountryValue(userData.country || "");
        setAddressValue(userData.business_address || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, [shouldReload]);

  const handlePanCardUpdate = async () => {
    const token = sessionStorage.getItem("Token");
    if (panCardValue.length === 10  && !panCardError) {
      try {
        setIsLoading(true); // Set loading state for API call
        const response = await axios.patch(
          `https://haartistaloud-backend.kollywoodhungama.com/v3/PAN/${token}`,
          {
            PANNo: panCardValue, // The updated PAN card number
          }
        );
        console.log("PAN Card updated successfully:", response.data);
        setIsEditable(false); // Disable editing after update
        setPanCardError(""); // Clear any errors
      } catch (error) {
        console.error("Error updating PAN Card:", error);
        setPanCardError("Failed to update PAN Card.");
      } finally {
        setIsLoading(false); // Reset loading state
      }
    } else {
      setPanCardError("The PAN card number must be exactly 10 characters.");
    }
  };

  const handleReload = () => {
    setShouldReload((prevState) => !prevState); // Toggle reload state
  };

  return (
    <>
      <div className="container flex">
        <button
          className="my-5 text-[#FF6C2F] text-[1.6rem] w-auto h-[5rem]  ml-[13.2%]"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <Link to="/account-details">Account Details</Link>
          <hr />
        </button>
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] ml-[4.1%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/social-media">Social Media</Link>
        </button>
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] m-auto ml-[4.1%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/bank-details">Bank Details</Link>
        </button>
      </div>
      <div className="container1">
        <div className="profileBar w-[28%]  bg-[#ffffff0d] rounded-md">
          <ProfilePicture artistName={stringValue} />
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] mx-0 flex-row"
            >
              Full Name
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[95%] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="Artist Aloud"
              value={stringValue}
              onChange={handleStringChange}
              required
              disabled
            />
            <br />
            {stringError && <span style={{ color: "red" }}>{stringError}</span>}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Email
            </label>
            <input
              type="email"
              className="rounded-[5px] bg-[#4c4c4c] w-[95%] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="example@domain.com"
              value={emailValue}
              onChange={handleEmailChange}
              required
              disabled
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Contact Number
            </label>
            <div className="flex flex-row w-[95%]">
              <div className="">
                <input
                  type="text"
                  className="rounded-[5px] bg-[#4c4c4c] w-[3.3rem] h-[2.8rem] mr-[0.7rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
                  placeholder="+91"
                  value={numberCodeValue}
                  onChange={handleNumberCodeChange}
                  required
                  disabled
                />
              </div>
              <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[31rem] h-[2.8rem]">
                <input
                  type="text"
                  className="bg-transparent grow-[0.95] text-[#FFFFFF] font-[Poppins] pr-[2rem] pl-[0.7rem]"
                  placeholder="9876543210"
                  value={numberValue}
                  onChange={handleNumberChange}
                  required
                  disabled={!isEditing}
                />
                {/* <button className="mr-2" onClick={handleNumberIconClick}>
                  <img
                    src={"./assets/union-1.png"}
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </button> */}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Alternate Contact Number
            </label>
            <div className="flex flex-row w-[95%]">
              <div className="">
                <input
                  type="text"
                  className="rounded-[5px] bg-[#4c4c4c] w-[3.3rem] h-[2.8rem] mr-[0.7rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
                  placeholder="+91"
                  value={numberCodeValue}
                  onChange={handleNumberCodeChange}
                  required
                  disabled
                />
              </div>
              <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[31rem] h-[2.8rem]">
                <input
                  type="text"
                  className="bg-transparent grow-[0.95] text-[#FFFFFF] font-[Poppins] pr-[2rem] pl-[0.7rem]"
                  placeholder="9876543210"
                  value={altNumberValue}
                  onChange={handleNumberChange}
                  required
                  disabled={!isEditing}
                />
                <button className="mr-2" onClick={handleAltNumberIconClick}>
                  <img
                    src={"./assets/union-1.png"}
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Pan Card Number
            </label>
            <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[95%] h-[2.8rem]">
              <input
                type="text"
                className="bg-transparent grow-[0.96] text-[#FFFFFF] font-[Poppins] pr-[2rem] pl-[0.7rem]"
                placeholder="CCSDP274622"
                value={panCardValue}
                onChange={handlePanCardChange}
                required
                disabled={!isEditable}
              />
              {!isEditable ? (
                <button className="mr-2" onClick={handlePancardIconClick}>
                  <img
                    src={"./assets/union-1.png"}
                    alt="Edit"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </button>
              ) : (
                <button
                  className="mr-2"
                  onClick={handlePanCardUpdate}
                  disabled={isLoading}
                  style={{
                    backgroundColor: "#4caf50", // Green background for update button
                    color: "white",
                    padding: "3px 7px",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              )}
            </div>
            {panCardError && <p className="text-red-500">{panCardError}</p>}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Country
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[95%] h-[2.8rem] mt-[0%] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="India"
              value={countryValue}
              onChange={handleCountryChange}
              required
              disabled
            />
          </div>
          <div></div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Address
            </label>
            <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[95%] h-[2.8rem] mb-[10%]">
              <input
                type="text"
                className="bg-transparent grow-[0.96] text-[#FFFFFF] font-[Poppins] pr-[2rem] pl-[0.7rem]"
                placeholder="Address"
                value={addressValue}
                onChange={handleAddressChange}
                required
                disabled={!isEditable}
              />
              <button className="mr-2" onClick={handleIconClick}>
                <img
                  src={"./assets/union-1.png"}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        {showAddressPopup && (
          <AddressPopup
            onClose={handleCloseAddressPopup}
            onSave={handleSaveAddress}
          />
        )}
      </div>
      <div>
        {showNumberPopup && <NumberPopup onClose={handleCloseOtpPopup} />}
      </div>
      <div>
        {showAltNumberPopup && (
          <AltNumberPopup onClose={handleCloseAltNumberPopup} />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AccountDetails;
