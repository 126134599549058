import React, { useState } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AttachFile() {
  const { contentType, showIprs } = useContent();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let isValid = false;
      let errorMessage = "";

      if (contentType === "audio") {
        if (file.size > 100 * 1024 * 1024) {
          errorMessage = "Audio file size exceeds 100 MB.";
        } else if (!file.name.endsWith(".wav")) {
          errorMessage = "Only .wav files are allowed for audio.";
        } else {
          isValid = true;
        }
      } else if (contentType === "video") {
        if (file.size > 500 * 1024 * 1024) {
          errorMessage = "Video file size exceeds 500 MB.";
        } else if (!file.name.endsWith(".mp4") && !file.name.endsWith(".mov")) {
          errorMessage = "Only .mp4 and .mov files are allowed for video.";
        } else {
          isValid = true;
        }
      }

      if (isValid) {
        setFileName(file);
        setFileError("");
        setProgress(0); // Reset progress
      } else {
        setFileName("");
        setFileError(errorMessage);
      }
    } else {
      setFileName("");
      setFileError("");
    }
  };

  const handleNextClick = async () => {
    if (!fileName) {
      alert("Please upload a file before proceeding.");
      return;
    }

    const contentId = localStorage.getItem("contentId");

    // Start file upload process
    const formData = new FormData();
    formData.append("audioVideo", fileName);
    formData.append("contentId", contentId);

    try {
      const token = sessionStorage.getItem("Token");
      setIsUploading(true);
      setProgress(0);

      const response = await axios.put(
        `https://haartistaloud-backend.kollywoodhungama.com/v2/uploadAudioVideo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      console.log("File upload successful:", response.data);
      toast.success("File uploaded successfully!");
      setTimeout(() => {
        navigate("/cover-image");
      }, 2000);
       // Redirect to the next page
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("File upload failed. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md ">
        <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          {showIprs && (
            <>
              <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
                <p>IPRS</p>
              </div>
              <br />
            </>
          )}
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <p>Attach Audio</p>
            )}
            {contentType === "video" && (
              <p>Attach Video</p>
            )}
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className="mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%]">
            <div>
              {contentType === "audio" && (
                <h2
                  className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Upload Audio
                </h2>
              )}
              {contentType === "video" && (
                <h2
                  className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Upload Video
                </h2>
              )}
            </div>
            <div>
              <button className="w-[8rem] h-[2.3rem] text-[#FFFFFF] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[7%]">
                <label htmlFor="file-upload" className="cursor-pointer">
                  Choose File
                </label>
              </button>
              <input
                id="file-upload"
                type="file"
                hidden
                accept={contentType === "audio" ? ".wav" : ".mp4, .mov"}
                onChange={handleFileChange}
              />
            </div>

            {isUploading && (
              <div className="w-[50%] h-6 bg-gray-300 rounded-full my-4  shadow-lg relative overflow-hidden">
                {/* Animated Gradient Shimmer Effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-[#FF6C2F] to-[#FF9F6C] animate-pulse opacity-30"></div>

                {/* Progress Bar with Glow */}
                <div
                  className="relative bg-gradient-to-r from-[#FF6C2F] to-[#FF9F6C] h-full rounded-full transition-all duration-700 ease-in-out shadow-[0_0_10px_2px_#FF6C2F]"
                  style={{ width: `${progress}%` }}
                >
                  {/* Progress Text */}
                  <span className="absolute inset-0 flex items-center justify-center text-white font-bold text-sm">
                    {progress}%
                  </span>
                </div>
              </div>
            )}

            <div className="w-[100%] h-[35%] bg-[#ffffff0d] rounded-md text-[#FFFFFF] pl-[25px] py-4 overflow-hidden text-ellipsis whitespace-nowrap flex items-center">
              {fileName && (
                <>
                  {/* Conditional Inline SVG for Audio or Video */}
                  {contentType === "audio" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 mr-2"
                    >
                      <path d="M8.25 4.5a3.75 3.75 0 1 1 7.5 0v8.25a3.75 3.75 0 1 1-7.5 0V4.5Z" />
                      <path d="M6 10.5a.75.75 0 0 1 .75.75v1.5a5.25 5.25 0 1 0 10.5 0v-1.5a.75.75 0 0 1 1.5 0v1.5a6.751 6.751 0 0 1-6 6.709v2.291h3a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1 0-1.5h3v-2.291a6.751 6.751 0 0 1-6-6.709v-1.5A.75.75 0 0 1 6 10.5Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 mr-2"
                    >
                      <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
                    </svg>
                  )}
                </>
              )}
              {/* File Name Preview */}
              <h2 className="opacity-[50]">
                {fileName ? fileName.name : "Preview"}
              </h2>
            </div>

            {fileError && (
              <div className="text-[#FF6C2F] mt-2">
                <p>{fileError}</p>
              </div>
            )}
          </div>

          <div className="w-[35rem] rounded-md my-4">
            {contentType === "audio" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-row gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-col w-[50%] gap-4">
                  <div className="flex flex-row gap-6">
                    <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                      <span>Maximum Limit</span>
                      <span className="text-[#FF6C2F] text-[1.4rem] font-bold">
                        100 mb
                      </span>
                    </div>
                    <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                      <span>File should be in</span>{" "}
                      <span className="text-[#FF6C2F] text-[1.4rem] font-bold">
                        .wav
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please ensure the audio isn't muted during the track.
                    </span>
                    <img
                      src="./assets/Frame561.png"
                      alt=""
                      className="w-[214px] h-[58px] text-center"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure that the track begins in</span>
                    <span className="text-[#FF6C2F] text-[1.4rem]">
                      {" "}
                      Maximum 5 seconds
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please ensure that the track is properly trimmed
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        {" "}
                        particularly towards the end.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col w-[50%] gap-4">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[17px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't mention{" "}
                      </span>
                      any music label, recording studios during the track.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload
                      </span>{" "}
                      any cover songs.
                    </span>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload{" "}
                      </span>{" "}
                      any poem or podcast content.
                    </span>
                    <img
                      src="./assets/Frame563.png"
                      alt=""
                      className="w-[135px] h-[48px]"
                    />
                  </div>
                </div>
              </div>
            )}
            {contentType === "video" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-row gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-col w-[50%] gap-4">
                  <div className="flex flex-row gap-6">
                    <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                      <span>Maximum Limit</span>
                      <span className="text-[#FF6C2F] text-[1.2rem] font-bold">
                        500 mb
                      </span>
                    </div>
                    <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[10px] gap-0.5">
                      <span>File should be in</span>{" "}
                      <span className="text-[#FF6C2F] text-[0.9rem] font-bold">
                        .mp4 <span className="text-slate-200">&</span>{" "}
                        <span className="text-[#FF6C2F] text-[0.9rem] font-bold">
                          .mov
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please ensure the audio isn't muted during the track.
                    </span>
                    <img
                      src="./assets/Frame561.png"
                      alt=""
                      className="w-[214px] h-[58px] text-center"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure there are no </span>
                    <span className="text-[#FF2F2F] text-[0.8rem]">
                      social media handles{" "}
                      <span className="text-slate-200">, </span>music platforms{" "}
                      <span className="text-slate-200"> or </span> brand logos
                    </span>{" "}
                    <span>during and after the video ends.</span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please ensure there is{" "}
                      <span className="text-[#FF2F2F] text-[0.8rem]">no</span>{" "}
                      upcoming track teaser
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        {" "}
                        at the end of video.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col w-[50%] gap-4">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[17px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't mention{" "}
                      </span>
                      any music label, recording studios during the track.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload
                      </span>{" "}
                      any cover songs.
                    </span>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload{" "}
                      </span>{" "}
                      any poem or commentary videos.
                    </span>
                    <img
                      src="./assets/Frame563.png"
                      alt=""
                      className="w-[135px] h-[48px]"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">No</span>{" "}
                      jukebox videos.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please{" "}
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        don't
                      </span>{" "}
                      upload any short film or movie videos.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="mb-[5%] w-[80%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              // className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              className={`text-[#FFFFFF] w-[6rem] h-[3rem] rounded-md ${
                isUploading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#FF6C2F] hover:bg-[#d95a25] active:bg-[#c15122]"
              }`}
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
              disabled={isUploading}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
