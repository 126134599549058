import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProfilePicture from "./ProfilePicture";

export default function SocialMedia() {
  const [isEditing, setIsEditing] = useState(false);

  const [instaLink, setInstaLink] = useState("");
  const [spotifyLink, setSpotifyLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");

  const [fullName, setFullName] = useState("");

  const [originalData, setOriginalData] = useState({});

  // Handle edit mode
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Handle save click (send data to API)
  const handleSaveClick = async () => {
    try {
      // Get the token from session storage
      const token = sessionStorage.getItem('Token');

      // Create the data to send in the PUT request
      const updatedData = {
        instagram: instaLink,
        facebook: fbLink,
        youtube: youtubeLink,
        twitter: twitterLink,
        spotify: spotifyLink,
      };

      // Make the PUT request to update social media links
      const response = await axios.put(
        `https://haartistaloud-backend.kollywoodhungama.com/v3/socialMedia/${token}`,
        updatedData
      );

      // Check if the response is successful
      if (response.status === 200) {
        // Update the original data with the new links
        setOriginalData({
          instaLink,
          spotifyLink,
          fbLink,
          youtubeLink,
          twitterLink,
        });
        setIsEditing(false);
        alert("Social media links updated successfully!");
      }
    } catch (error) {
      console.error("Error updating social media links:", error);
      alert("There was an error updating your social media links.");
    }
  };

  // Handle cancel edit
  const handleCancelClick = () => {
    // Reset to original data
    setInstaLink(originalData.instaLink || "");
    setSpotifyLink(originalData.spotifyLink || "");
    setFbLink(originalData.fbLink || "");
    setYoutubeLink(originalData.youtubeLink || "");
    setTwitterLink(originalData.twitterLink || "");
    setIsEditing(false);
  };

  // Handle input changes
  const handleInstaChange = (e) => setInstaLink(e.target.value);
  const handleSpotifyChange = (e) => setSpotifyLink(e.target.value);
  const handleFbChange = (e) => setFbLink(e.target.value);
  const handleYoutubeChange = (e) => setYoutubeLink(e.target.value);
  const handleTwitterChange = (e) => setTwitterLink(e.target.value);

  // Fetch user data on load
  useEffect(() => {
    const getUserInfo = async () => {
      const token = sessionStorage.getItem('Token');
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
        const userData = response.data.data[0];

        // Set user data
        setFullName(userData.full_name || "Artist Name");
        setInstaLink(userData.instagram);
        setSpotifyLink(userData.spotify);
        setFbLink(userData.facebook);
        setYoutubeLink(userData.youtube);
        setTwitterLink(userData.twitter);

        setOriginalData({
          instaLink: userData.instagram,
          spotifyLink: userData.spotify,
          fbLink: userData.facebook,
          youtubeLink: userData.youtube,
          twitterLink: userData.twitter,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, []);

  return (
    <>
      <div className="flex">
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] ml-[12.5%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/account-details">Account Details</Link>
        </button>
        <button
          className="my-5 text-[#FF6C2F] text-[1.6rem] w-auto h-[5rem] ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/social-media">Social Media</Link>
          <hr />
        </button>
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] m-auto ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/bank-details">Bank Details</Link>
        </button>
      </div>
      <div className="container1">
        <div className="profileBar w-[28%] bg-[#ffffff0d] rounded-md">
          <ProfilePicture artistName={fullName} />
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] w-[80%] flex-row"
            >
              Instagram
            </label>
            <div>
              <input
                type="url"
                className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
                placeholder="https://instagram.com/xyz"
                onChange={handleInstaChange}
                readOnly={!isEditing}
                value={instaLink}
              />
              {/* {!isValid && (
                <p className="error-message">
                  Please enter a valid social media link.
                </p>
              )} */}
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Spotify
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="https://open.spotify.com/artist/4YRxDV8wJFPHPTeXepOstw"
              onChange={handleSpotifyChange}
              readOnly={!isEditing}
              value={spotifyLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Facebook
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="https://www.facebook.com/xyz"
              onChange={handleFbChange}
              readOnly={!isEditing}
              value={fbLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              YouTube
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="https://www.youtube.com/xyz"
              onChange={handleYoutubeChange}
              readOnly={!isEditing}
              value={youtubeLink}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Twitter
            </label>
            <input
              type="url"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              onChange={handleTwitterChange}
              readOnly={!isEditing}
              value={twitterLink}
            />
          </div>
          <div>
            {isEditing ? (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#6E6E6E] rounded-md hover:bg-[#484848] active:bg-[#484848]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                    marginBottom: "40px",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  className="mb-[14%] text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md ml-[1%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md mb-[14%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
