import React, { useRef } from "react";

export default function MarketingPlans() {
  const plansRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        backgroundImage: "url('/assets/lines.png')",
        backgroundSize: "100vw",
        backgroundRepeat: "no-repeat",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div className="flex">
        <h2
          className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Marketing <span className="text-[#FEC961] ml-1">Services</span>
        </h2>
      </div>
      <div className="flex flex-col text-[#FFFFFF]">
        <div className="text-[3.5rem] mt-[6%] mb-[3%] ml-[12%] flex items-center justify-left">
          <p className="w-[70%] marketingPlansTitle">
            {`Want your music to reach 
          your desired audience?`}
          </p>
        </div>
        <div className="marketing-plansTop flex gap-5 w-[70%] ml-[12%]">
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer "
            style={{
              background: "#2f3132",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Free Plan</div>
          </div>
          <div
            className=" px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#4ba9c8",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Silver Plan</div>
          </div>
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "rgba(255, 193, 71, 0.53)",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Gold Plan</div>
          </div>

          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#8F00FF33",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Platinum Plan</div>
          </div>
        </div>
        <div className="w-[80%] text-[0.75rem] mt-[3%] ml-[12%] flex items-center justify-center">
          <p>
            {`At Artist Aloud, transparency and simplicity are at the core of what we do. We believe in offering clear and fair pricing without any hidden surprises. Our goal is to provide you with the best value for our services/products while ensuring you understand exactly what you're getting.`}
          </p>
        </div>
        <div className="mt-[15%] mb-[3%] flex flex-col items-end">
          <div className="section2Title w-[40%]">
            <p className=" marketingPlansTitle text-[3.5rem] mb-4">
              {`Promote Your Music on Social Platforms`}
            </p>
            <div className="flex justify-start w-[100%]">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Platinum Plan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[15%] mb-[3%] ml-[12%] flex flex-col items-start">
          <div className="w-[55%] marketing-plansbottomDiv">
            <p className="marketingPlansTitle text-[3.5rem] mb-4">
              {"Get the benefits of Enhanced distribution packages"}
            </p>
            <div className="marketing-plansbottom flex flex-row justify-start gap-5">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Platinum Plan</div>
              </div>
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "rgba(255, 193, 71, 0.53)",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Gold Plan</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-center rounded-md ml-auto mr-auto w-[90%] mt-[5%] pb-[4%]"
          style={{
            background: `linear-gradient(0deg, #191919, #191919), 
                 linear-gradient(219.73deg, rgba(255, 255, 255, 0.2) -20.78%, rgba(0, 0, 0, 0) 77.31%),
                 linear-gradient(330.82deg, rgba(0, 0, 0, 0.4) -5.18%, rgba(161, 161, 161, 0.1) 92.84%)`,
          }}
          ref={plansRef}
        >
          <div className="flex flex-row my-6 w-[100%] ml-[2%] ">
            <p className="marketingPlansTitleBox text-[3.2rem] w-[40%] ml-10">
              Select the <span className="text-[#FF6C2F]">plan</span> that fits
              you
            </p>
            <div className="w-[30%] ml-[18%] my-6">
              <p className="text-[1.1rem] p-4 border-[2px] border-gray-300 rounded-md">
                For{" "}
                <span className="text-[#FF6C2F] font-semibold">
                  customised plan
                </span>{" "}
                to be discussed with a representative, reach out to{" "}
                <a
                  href="mailto:artistaloud@hungama.com"
                  className="underline inline-flex items-center gap-1 mt-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>
                  artistaloud@hungama.com
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center w-full hidden lg:flex">
            <table className="w-[90%] text-sm table-auto border-collapse">
              <thead>
                <tr>
                  <th className="text-left border-b border-gray-500 py-4"></th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #7f7f7f",
                    }}
                  >
                    <div className="text-[1.5rem] font-bold ">FREE</div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #add8e6",
                    }}
                  >
                    <div>Silver Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>999
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #ffc147",
                    }}
                  >
                    <div>Gold Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>1499
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #9d46ef",
                    }}
                  >
                    <div>Platinum Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>2499
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">Releases</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Social Media Announcements
                  </td>
                  <td className="text-center text-white">1</td>
                  <td className="text-center text-white">2</td>
                  <td className="text-center text-white">4</td>
                  <td className="text-center text-white">4</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Chatbot Engagement
                  </td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Custom Release Date
                  </td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Revenue Split (Artist Revenue)
                  </td>
                  <td className="text-center text-white text-[0.8rem]">50%</td>
                  <td className="text-center text-white text-[0.8rem]">60%</td>
                  <td className="text-center text-white text-[0.8rem]">70%</td>
                  <td className="text-center text-white text-[0.8rem]">75%</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Customer Service Response Time
                  </td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-white text-[0.8rem]">
                    72 hours
                  </td>
                  <td className="text-center text-white text-[0.8rem]">
                    48 hours
                  </td>
                  <td className="text-center text-white text-[0.8rem]">
                    24 hours
                  </td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Playlist Pitching Services
                  </td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                {/* Button Row (No horizontal line here) */}
                <tr>
                  <td></td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      {/* Buy Now */}
                      Coming Soon
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      {/* Buy Now */}
                      Coming Soon
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      {/* Buy Now */}
                      Coming Soon
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      {/* Buy Now */}
                      Coming Soon
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Div for smaller screens */}
          <div className="w-full px-8 block lg:hidden">
            <div className="flex flex-col items-center text-white space-y-4">
              <div className="p-4 rounded-lg border border-[#9d46ef] border-2 w-full">
                <h3 className="text-lg font-bold text-[#9d46ef]">
                  Platinum Plan
                </h3>
                <p className="text-sm mt-4">
                  <span className="text-[#FF6C2F] font-semibold">80% </span>
                  Revenue Share—keep the most.
                </p>
                <p className="text-sm mt-4">4 Social Media Announcements</p>
                <p className="text-sm mt-4">
                  Playlist Pitching Services to get featured.
                </p>
                <p className="text-sm mt-4">
                  "Maximize your success with premium tools and support!"
                </p>
                <button className="mt-4 px-4 py-2 bg-orange-500 rounded-md text-white">
                  Buy Now
                </button>
              </div>
              <div className="p-4 rounded-lg border border-[#ffc147] border-2 w-full">
                <h3 className="text-lg font-bold text-[#ffc147]">Gold Plan</h3>
                <p className="text-sm mt-4">
                  <span className="text-[#FF6C2F] font-semibold">75% </span>
                  Revenue Share
                </p>
                <p className="text-sm mt-4">4 Social Media Announcements</p>
                <p className="text-sm mt-4">Faster Support (3-5 days)</p>
                <p className="text-sm mt-4">
                  "Grow faster with more earnings and better promotion!"
                </p>
                <button className="mt-4 px-4 py-2 bg-orange-500 rounded-md text-white">
                  Buy Now
                </button>
              </div>
              <div className="p-4 rounded-lg border border-[#add8e6] border-2 w-full">
                <h3 className="text-lg font-bold text-[#add8e6]">
                  Silver Plan
                </h3>
                <p className="text-sm mt-4">
                  {" "}
                  <span className="text-[#FF6C2F] font-semibold">70% </span>
                  Revenue Share
                </p>
                <p className="text-sm mt-4">2 Social Media Announcements</p>
                <p className="text-sm mt-4">
                  "Step up your game—earn more, get noticed, and grow your
                  audience."
                </p>
                <button className="mt-4 px-4 py-2 bg-orange-500 rounded-md text-white">
                  Buy Now
                </button>
              </div>
              <div className="p-4 rounded-lg border border-[#7f7f7f] border-2 w-full">
                <h3 className="text-lg font-bold">Free Plan</h3>
                <p className="text-sm mt-4">Unlimited Music Releases</p>
                <p className="text-sm mt-4">
                  Zero Cost +{" "}
                  <span className="text-[#FF6C2F] font-semibold">50% </span>
                  Revenue Share
                </p>
                <p className="text-sm mt-4">
                  "Start your journey for free—get your music out to the world!"
                </p>
                <button className="mt-4 px-4 py-2 bg-orange-500 rounded-md text-white">
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[90%] text-[0.9rem] mt-[3%] ml-[5%] flex flex-col mb-[12%]">
          <p>
            {`Please note that all prices listed above are valid for a one-time use only. Subsequent use or additional services may be subject to revised pricing. For further details on multiple-use pricing or extended services, kindly reach out to our customer service team.`}{" "}
            <a
              href="mailto:artistaloud@hungama.com"
              className="underline md:underline-offset-4"
            >
              artistaloud@hungama.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
