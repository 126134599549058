import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { MainContext, useContent } from "../context/main.js";
import axios from "axios";
import { country } from "../constant/country";
import { state } from "../constant/state";

const AddressPopup = ({ onClose, onSave }) => {
  const [countryId, setCountryId] = useState(0);
  const [countryName, setCountryName] = useState("");
  const [stateId, setStateId] = useState(0);
  const [stateName, setStateName] = useState("");
  const [address, setAddress] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincodeValue, setPincodeValue] = useState();
  const [isValid, setIsValid] = useState(true);

  const [addressError, setAddressError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [errors, setErrors] = useState({});
  const formData = useContent(MainContext);

  const getStatesByCountry = () => {
    const selectState = state.filter((item) => item.id == countryId);
    if (selectState.length === 0) {
      console.log("No states found for the selected country.");
      return [];
    }
    let states = selectState[0];
    states = states.states;
    return states;
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const token = sessionStorage.getItem("Token");
      if (!token) {
        toast.error("Token not found.");
        return;
      }

      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
        const userData = response.data.data[0];

        console.log("API response data:", userData);

        // Setting initial values from API response
        setAddress(userData.business_address || "");
        setCityValue(userData.city || "");
        setStateName(userData.state || "");
        setCountryName(userData.country || "");
        setPincodeValue(userData.zipcode || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserInfo();
  }, []);

  const handleAddressChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ',+-]*$/;

    if (regex.test(value) && value.length <= 200) {
      setAddress(value);
      setAddressError("");
    } else if (!regex.test(value)) {
      setAddressError("Only alphanumeric values are allowed.");
    } else {
      setAddressError("The input must be exactly 200 characters.");
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 30) {
      setCityValue(value);
      setCityError("");
    } else {
      setCityError("City name must be less than 30 characters.");
    }
  };

  const handleStateChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z.,A-Z\s]*$/;

    if (regex.test(value) && value.length <= 20) {
      setStateName(value);
      setStateError("");
    } else {
      setStateError("State name must be less than 20 characters.");
    }
  };

  const handleCountryChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 50) {
      setCountryName(value);
      setCountryError("");
    } else {
      setCountryError("Country name must be less than 50 characters.");
    }
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{4,12}$/.test(value);
    setPincodeValue(value);
    setIsValid(isValidInput);
  };

  const handleSaveClick = async () => {
    try {
      if (
        !address ||
        !cityValue ||
        !stateName ||
        !countryName ||
        !pincodeValue ||
        !isValid
      ) {
        toast.error("Please fill all fields with valid values.");
        return;
      }

      const token = sessionStorage.getItem("Token");

      const payload = {
        businessAddress: address,
        city: cityValue,
        state: stateName,
        pincode: pincodeValue,
        country: countryName,
      };

      const url = `https://haartistaloud-backend.kollywoodhungama.com/v3/businessAddress/${token}`;

      console.log("Sending payload to:", url, "Payload:", payload);

      const response = await axios.patch(url, payload);

      if (response.status === 200) {
        toast.success("Address updated successfully!");
        onSave(address, cityValue, stateName, countryName, pincodeValue);
        onClose();
      } else {
        throw new Error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating address:", error.response?.data || error);
      toast.error(
        error.response?.data?.message ||
          "Failed to update address. Please try again."
      );
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <div>
          <h1
            style={{ fontWeight: "400", fontSize: "25px", marginBottom: "6%" }}
          >
            Add Address
          </h1>
        </div>
        <div className="labelHeading">
          <label
            htmlFor=""
            className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%]"
          >
            Business Address
          </label>
        </div>
        <div>
          <input
            type="text"
            className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
            placeholder="Address"
            value={address}
            onChange={handleAddressChange}
            required
            style={{ marginBottom: "4%", marginTop: "1.5%" }}
          />
        </div>
        <div className="flex flex-column justify-between">
          <div className="flex-column">
            <div className="labelHeading">
              <label htmlFor="" className="text-[#FFFFFF] flex-column">
                City
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="City"
                value={cityValue}
                onChange={handleCityChange}
                required
                style={{ marginBottom: "8%", marginTop: "2%" }}
              />
            </div>
          </div>
          <div className="flex-column">
            <div className="labelHeading">
              <label htmlFor="" className="text-[#FFFFFF] flex-column">
                State
              </label>
            </div>
            <div>
              {/* <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[1%] mb-[35%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="State"
                value={stateValue}
                onChange={handleStateChange}
                required
                style={{ marginBottom: "8%", marginTop: "2%" }}
                
              /> */}
              <select
                name="state"
                className={`p-2 rounded-[5px] bg-[#4c4c4c] w-56 h-[2.7rem] mt-[1%] text-[#FFFFFF] font-[Poppins] flex-column outline-none ${
                  errors.state ? "border-red-500" : ""
                }`}
                value={stateId}
                onChange={(e) => {
                  const selectedStateId = e.target.value;
                  const selectedState = getStatesByCountry().find(
                    (item) => item.id == selectedStateId
                  );
                  setStateId(selectedStateId);
                  setStateName(selectedState?.name || "");
                  formData.state = selectedState?.name || "";
                  setErrors({ ...errors, state: "" }); // Clear error on change
                }}
              >
                <option value="">Select State</option>
                {getStatesByCountry().map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <p className="text-red-500 text-xs">{errors.state}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-column justify-between">
          <div className="flex-column">
            <div className="labelHeading">
              <label
                htmlFor=""
                className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%] flex-column"
              >
                Pin code
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[100%] h-[2.7rem] mt-[1%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="Pin code"
                value={pincodeValue}
                onChange={handlePincodeChange}
                required
                style={{ marginTop: "2%", maxWidth: "100%" }}
              />
            </div>
          </div>
          <div className="flex-column">
            <div className="labelHeading">
              <label
                htmlFor=""
                className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%] flex-column"
              >
                Country
              </label>
            </div>
            <div>
              {/* <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[1%] mb-[35%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="Country"
                value={countryValue}
                onChange={handleCountryChange}
                required
                style={{ marginTop: "2%" }}
              /> */}
              <select
                name="country"
                className={`p-2 rounded-[5px] bg-[#4c4c4c] w-56 h-[2.7rem] mt-[1%] text-[#FFFFFF] font-[Poppins] flex-column outline-none ${
                  errors.country ? "border-red-500" : ""
                }`}
                value={countryId}
                onChange={(e) => {
                  const selectedCountryId = e.target.value;
                  const selectedCountry = country.find(
                    (item) => item.id == selectedCountryId
                  );
                  setCountryId(selectedCountryId);
                  setCountryName(selectedCountry?.name || "");
                  formData.country = selectedCountry?.name || "";
                  setStateId("");
                  setStateName("");
                  setErrors({ ...errors, country: "" }); // Clear error on change
                }}
              >
                <option value="">Select Country</option>
                {country.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="text-red-500 text-xs">{errors.country}</p>
              )}
            </div>
          </div>
        </div>
        {!isValid && (
          <p className="" style={{ fontSize: "11px", maxWidth: "52%" }}>
            Please enter a valid Pincode (minimum 4 maximum 12 digits)
          </p>
        )}
        <div className="button-group">
          <button onClick={handleSaveClick}>Save</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddressPopup;
