import React, { useState, useEffect } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

export default function ContentDistribution() {
  const { contentType, showIprs } = useContent();
  const [isClickedJio, setIsClickedJio] = useState(true);
  const [isClickedAmazon, setIsClickedAmazon] = useState(true);
  const [isClickedSpotify, setIsClickedSpotify] = useState(true);
  // const [isClickedWynk, setIsClickedWynk] = useState(true);
  const [isClickedMeta, setIsClickedMeta] = useState(true);
  const [isClickedItunes, setIsClickedItunes] = useState(true);
  const [selectedValue, setSelectedValue] = useState("Exclusive");
  const [showExclusivePlatforms, setShowExclusivePlatforms] = useState(true);
  const navigate = useNavigate();

  const [platformsState, setPlatformsState] = useState({
    Jio: false,
    Amazon: false,
    Spotify: false,
    Meta: false,
    Itunes: false,
  });
  // const subscriptionPlan = localStorage.getItem("subscriptionPlan");

  const handleNextClick = () => {
    navigate("/terms-uploads");
  };

  const checkAllPlatforms = (jio, amazon, spotify, meta, itunes) => {
    const allSelected = jio && amazon && spotify && meta && itunes;

    if (allSelected) {
      setSelectedValue("Exclusive");
      storeToLocalStorage("Exclusive");
    } else {
      setSelectedValue("Non-Exclusive");
      storeToLocalStorage("Non-Exclusive");
    }
  };

  const handleClickPlatform = (platform) => {
    const toggleValue = {
      Jio: () =>
        setIsClickedJio((prev) => {
          const newValue = !prev;
          checkAllPlatforms(
            newValue,
            isClickedAmazon,
            isClickedSpotify,
            isClickedMeta,
            isClickedItunes
          );
          return newValue;
        }),
      Amazon: () =>
        setIsClickedAmazon((prev) => {
          const newValue = !prev;
          checkAllPlatforms(
            isClickedJio,
            newValue,
            isClickedSpotify,
            isClickedMeta,
            isClickedItunes
          );
          return newValue;
        }),
      Spotify: () =>
        setIsClickedSpotify((prev) => {
          const newValue = !prev;
          checkAllPlatforms(
            isClickedJio,
            isClickedAmazon,
            newValue,
            isClickedMeta,
            isClickedItunes
          );
          return newValue;
        }),
      // Wynk: () =>
      //   setIsClickedWynk((prev) => {
      //     const newValue = !prev;
      //     checkAllPlatforms(
      //       isClickedJio,
      //       isClickedAmazon,
      //       isClickedSpotify,
      //       newValue,
      //       isClickedMeta,
      //       isClickedItunes
      //     );
      //     return newValue;
      //   }),
      Meta: () =>
        setIsClickedMeta((prev) => {
          const newValue = !prev;
          checkAllPlatforms(
            isClickedJio,
            isClickedAmazon,
            isClickedSpotify,
            newValue,
            isClickedItunes
          );
          return newValue;
        }),
      Itunes: () =>
        setIsClickedItunes((prev) => {
          const newValue = !prev;
          checkAllPlatforms(
            isClickedJio,
            isClickedAmazon,
            isClickedSpotify,
            isClickedMeta,
            newValue
          );
          return newValue;
        }),
    };

    // Ensure the platform exists and call its toggle function
    if (toggleValue[platform]) {
      toggleValue[platform]();
    }
  };

  // Updated handleClickExclusive
  useEffect(() => {
    // Debugging output
    console.log("selectedValue updated:", selectedValue);

    // Store to localStorage when the selected value changes
    if (selectedValue === "Exclusive" || selectedValue === "Non-Exclusive") {
      storeToLocalStorage(selectedValue);
    }
  }, [
    selectedValue,
    isClickedJio,
    isClickedAmazon,
    isClickedSpotify,
    isClickedMeta,
    isClickedItunes,
  ]);

  // useEffect(() => {
  //   const subscriptionPlan = localStorage.getItem("subscriptionPlan");

  //   if (subscriptionPlan === "platinum" || subscriptionPlan === "gold") {
  //     setShowExclusivePlatforms(true);
  //   } else {
  //     setShowExclusivePlatforms(false);
  //   }
  // }, []);

  const storeToLocalStorage = (distributionType) => {
    const platforms = {};

    console.log("Storing data for distributionType:", distributionType);

    if (distributionType === "Exclusive") {
      if (contentType === "audio") {
        // Store all platform names as true for Exclusive
        // {(subscriptionPlan === "platinum" || subscriptionPlan === "gold")?setShowExclusivePlatforms(true):setShowExclusivePlatforms(false)}
        setShowExclusivePlatforms(true);
        platforms.Jio = true;
        platforms.Amazon = true;
        platforms.Spotify = true;
        platforms.Meta = true;
        platforms.Itunes = true;
        platforms.Hungama = true;
      } 
      else if (contentType === "video") {
        platforms.Hungama = true;
        platforms.TataPlay = true;
        platforms.OnePlusTv = true;
        platforms.AirtelDigitalTv = true;
        platforms.MiTv = true;
        platforms.FireTv = true;
      }
    } else if (distributionType === "Non-Exclusive") {
      // Store only Hungama and clicked platforms for Non-Exclusive
      setShowExclusivePlatforms(false);
      platforms.Hungama = true;
      if (isClickedJio) platforms.Jio = true;
      if (isClickedAmazon) platforms.Amazon = true;
      if (isClickedSpotify) platforms.Spotify = true;
      if (isClickedMeta) platforms.Meta = true;
      if (isClickedItunes) platforms.Itunes = true;
    }

    // Create data object to store
    const dataToStore = {
      distributionType,
      platforms,
    };

    console.log("Data to store in localStorage:", dataToStore);

    // Store data in localStorage
    localStorage.setItem("platformSelection", JSON.stringify(dataToStore));
  };

  const handleClickNonExclusive = () => {
    // Set selected value to Non-Exclusive and reset platforms
    setSelectedValue("Non-Exclusive");
    setShowExclusivePlatforms(false);

    // Reset all clicked platform states to false
    setIsClickedJio(false);
    setIsClickedAmazon(false);
    setIsClickedSpotify(false);
    setIsClickedMeta(false);
    setIsClickedItunes(false);
  };

  const handleClickExclusive = () => {
    // Set selected value to Exclusive and set all platforms to true
    setSelectedValue("Exclusive");
    // {(subscriptionPlan === "platinum" || subscriptionPlan === "gold")?setShowExclusivePlatforms(true):setShowExclusivePlatforms(false)}
    setShowExclusivePlatforms(true);
    setIsClickedJio(true);
    setIsClickedAmazon(true);
    setIsClickedSpotify(true);
    setIsClickedMeta(true);
    setIsClickedItunes(true);
  };

  // useEffect(() => {
  //   handleClickExclusive({ target: { value: "Exclusive" } });
  // }, []);

  const buttonLabels = [
    "YouTube Music",
    "YouTube",
    "Tidal",
    "TikTok",
    "AMI Entertainment",
    "Fizy",
    "FLO",
    "Genie",
    "iHeart Radio",
    "IIM",
    "JAXSTA",
    "Kantar",
    "Line Music",
    "LiveOne (LiveXone)",
    "Mood Media - Playnetwork",
    "Napster",
    "NetEase",
    "RecoChoku",
    "RX Music",
    "SiriusXM",
    "SoundCloud",
    "SoundExchange",
    "Stellar Entertainment",
    "TiVo",
    "Trebel",
    "Triller",
    "Tuned Global",
    "Ververlife",
    "Yango Play",
    "7 Digital",
    "Anghami",
    "Audible Magic",
    "Audiomack",
    "Boomplay",
    "Bugs",
    "Claro Musica",
    "Deezer",
    "Gaana",
    "JOOX",
    "KKBOX",
    "Kuack Media Group",
    "KuGou(tencent)",
    "MediaNet",
    "Melon",
    "MOOV",
    "muud",
    "NCT (NhacCuaTui)",
    "Pandora",
    "Peloton",
    "Qobuz",
    "QQ_Music",
    "TouchTunes",
    "Vera",
    "VIBE_logo",
    "Zing mp3",
  ];
  // Labels for the buttons
  const buttonImages = [
    "./assets/youtube music.png",
    "./assets/youtubewhite.png",
    "./assets/tidal-logo.png",
    "./assets/Tiktok.png",
    "./assets/AMI Entertainment.png",
    "./assets/Fizy.png",
    "./assets/FLO.png",
    "./assets/genie.png",
    "./assets/iHeart Radio.png",
    "./assets/IIM.png",
    "./assets/Jaxsta.png",
    "./assets/Kantar.png",
    "./assets/Line Music.jpeg",
    "./assets/LiveXOne.webp",
    "./assets/Mood Media.png",
    "./assets/NAPSTER.png",
    "./assets/NetEase.png",
    "./assets/RecoChoku.png",
    "./assets/RX Music.png",
    "./assets/SiriusXM.jpg",
    "./assets/Soundcloud.jpeg",
    "./assets/SoundExchange.png",
    "./assets/Stellar Entertainment.webp",
    "./assets/TiVo.png",
    "./assets/Trebel.png",
    "./assets/Triller.png",
    "./assets/Tuned Global.webp",
    "./assets/vervelife.png",
    "./assets/yango play.png",
    "./assets/7 Digital.png",
    "./assets/Anghami.png",
    "./assets/Audible Magic.png",
    "./assets/Audiomack.png",
    "./assets/Boomplay.png",
    "./assets/Bugs.webp",
    "./assets/Claro Musica.png",
    "./assets/Deezer.png",
    "./assets/Gaana.png",
    "./assets/JOOX.png",
    "./assets/KKBOX.jpg",
    "./assets/Kuack Media Group.jpeg",
    "./assets/KuGou(tencent).png",
    "./assets/MediaNet.jpeg",
    "./assets/Melon.webp",
    "./assets/MOOV.jpg",
    "./assets/muud.png",
    "./assets/NCT (NhacCuaTui).jpg",
    "./assets/Pandora.png",
    "./assets/Peloton.jpg",
    "./assets/Qobuz.png",
    "./assets/QQ_Music.svg",
    "./assets/TouchTunes.jpeg",
    "./assets/Vera.png",
    "./assets/VIBE_logo.png",
    "./assets/Zing mp3.png",
  ];

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className=""
        style={{
          width: "auto",
          minHeight: "300px",
          height: "auto",
          marginLeft: "12%",
          marginRight: "12%",
          borderRadius: "5px",
          backgroundColor: "#ffffff0d",
          display: "flex",
          borderRadius: "10px",
          marginBottom: "8%",
        }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          {showIprs && (
            <>
              <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
                <p>IPRS</p>
              </div>
              <br />
            </>
          )}
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && <p>Attach Audio</p>}
            {contentType === "video" && <p>Attach Video</p>}
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className="ml-[auto] mr-[auto] mt-[6%] flex flex-col max-w-[70%] min-h-[700px]">
          {contentType === "audio" && (
            <>
              <div className="flex items-center justify-between mb-[10%]">
                <h2
                  className="text-[#FFFFFF] text-[0.9rem]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Platforms:
                </h2>
                <div>
                  <input
                    type="radio"
                    value="Exclusive"
                    id="Exclusive"
                    checked={selectedValue === "Exclusive"}
                    onChange={handleClickExclusive}
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor:
                        selectedValue === "Exclusive" ? "#FF6C2F" : "#242424",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Exclusive" ? "#FF6C2F" : "")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Exclusive" ? "#FF6C2F" : "")
                    }
                  />
                  <label
                    for="Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px] mr-[25px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Exclusive
                  </label>
                  <input
                    type="radio"
                    value="Non-Exclusive"
                    id="Non-Exclusive"
                    checked={selectedValue === "Non-Exclusive"}
                    onChange={handleClickNonExclusive}
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor:
                        selectedValue === "Non-Exclusive"
                          ? "#FF6C2F"
                          : "#242424",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Non-Exclusive" ? "#FF6C2F" : "")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Non-Exclusive" ? "#FF6C2F" : "")
                    }
                  />
                  <label
                    for="Non-Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Non-Exclusive
                  </label>
                </div>
              </div>

              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/hungamalogo1.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  onClick={() => handleClickPlatform("Meta")}
                  style={{
                    backgroundColor: isClickedMeta ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedMeta ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/metalogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "90px",
                    }}
                  />
                </button>
                <button
                  onClick={() => handleClickPlatform("Jio")}
                  style={{
                    backgroundColor: isClickedJio ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedJio ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/jiosaavanlogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>

              <div className="flex mb-[8%]">
                <button
                  onClick={() => handleClickPlatform("Amazon")}
                  style={{
                    backgroundColor: isClickedAmazon ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedAmazon ? "1px solid #FF6C2F" : "",
                  }}
                >
                  <img
                    src={"./assets/amazonmusic.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>

                <button
                  onClick={() => handleClickPlatform("Spotify")}
                  style={{
                    backgroundColor: isClickedSpotify ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedSpotify ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/spotifylogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>

                <button
                  onClick={() => handleClickPlatform("Itunes")}
                  style={{
                    backgroundColor: isClickedItunes ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedItunes ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/ituneslogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "80px",
                    }}
                  />
                </button>
              </div>

              {showExclusivePlatforms && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap", // Allow wrapping for rows
                    overflowX: "hidden", // Enable horizontal scrolling only
                    overflowY: "auto", // Disable vertical scrolling
                    // height: "30%",
                    gap: "3rem",
                    marginBottom:"5%",
                    maxWidth: "calc(4 * 8rem + 2 * 1rem)", // Limit width to fit 3 buttons
                  }}
                >
                  {buttonImages.map((image, index) => (
                    <button
                      key={index}
                      disabled
                      style={{
                        // backgroundColor: "#ffffff0d",
                        color: "white",
                        width: "8rem",
                        height: "5rem",
                        border: "none",
                        borderRadius: "20px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #FF6C2F",
                        padding: "0.3rem",
                        cursor: "not-allowed",
                      }}
                    >
                      <img
                        src={image}
                        alt={buttonLabels[index]}
                        style={{
                          width: "auto", // Ensure the image fits well inside the button
                          height: "auto", // Maintain aspect ratio
                          maxHeight: "100%", // Prevent overflow
                          objectFit: "contain", // Scale image proportionally
                        }}
                      />
                    </button>
                  ))}
                </div>
              )}
            </>
          )}

          {contentType === "video" && (
            <>
              <div className="flex items-center justify-between mb-[10%]">
                <h2
                  className="text-[#FFFFFF] text-[0.9rem]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Platforms:
                </h2>
                <div>
                  <input
                    type="radio"
                    value="Exclusive"
                    id="Exclusive"
                    // checked={selectedValue === "Exclusive"}
                    disabled
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor: "#FF6C2F",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                  />
                  <label
                    for="Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px] mr-[25px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Exclusive
                  </label>
                </div>
              </div>
              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/hungamalogo1.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/tata.png"}
                    alt="Button Icon"
                    style={{
                      width: "120px",
                    }}
                  />
                </button>
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/oneplustv.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>

              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/airtel.png"}
                    alt="Button Icon"
                    style={{
                      width: "200px",
                    }}
                  />
                </button>

                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/mi.png"}
                    alt="Button Icon"
                    style={{
                      width: "200px",
                    }}
                  />
                </button>

                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "5rem",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/fire.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>
              {/* <div className="flex justify-evenly mb-[10%]">
                
              </div> */}
            </>
          )}
          <div
            className="mb-[10%] w-[100%]"
            style={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
