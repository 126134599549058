import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import jsPDF from "jspdf";

export default function TermsUpload() {
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const { contentType, showIprs } = useContent();
  const formInfo = JSON.parse(localStorage.getItem("formData")) || {};
  const [panNumber, setPanNumber] = useState("");
  const [panCardError, setPanCardError] = useState("");
  const [licensorName, setLicensorName] = useState(
    formInfo.licensorValue || ""
  );
  const [artistName, setArtistName] = useState(formInfo.artistNameValue || "");
  const [albumName, setAlbumName] = useState(
    formInfo.artistLegalNameValue || ""
  );
  const [trackName, setTrackName] = useState(formInfo.trackNameValue || "");
  const [dateOfExecution, setDateOfExecution] = useState(
    formInfo.releaseDate || ""
  );
  const [showContent, setShowContent] = useState(false);
  const sigCanvas = useRef({});
  const [signature, setSignature] = useState(null);
  const [isPanText, setIsPanText] = useState(false);
  const navigate = useNavigate();
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [pdfdataSuccess, setPdfdataSuccess] = useState(false);
  const [agreementDate, setAgreementDate] = useState("");
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("en-GB", { month: "short" });
  const year = today.getFullYear().toString().slice(-2);
  const formattedDate = `${day} ${month} ${year}`;
  const formattedDateISO = today.toISOString().split("T")[0];

  const handleAgreeClick = async () => {
    if (!userInfo.panCard) {
      if (!panNumber) {
        toast.error("Please enter your PAN number before agreeing.");
        return;
      }
    }
    if (panCardError) {
      toast.error("Please enter valid PAN Card number");
      return;
    }

    if (!showContent) {
      toast.error("Please read the main agreement before agreeing.");
      return;
    }

    if (!agreementDate) {
      if (sigCanvas.current.isEmpty()) {
        // if (sigCanvas.current.isEmpty()) {
        toast.error("Please provide your digital signature before agreeing.");
        return;
      }
    }

    try {
      const token = sessionStorage.getItem("Token");
      if (!userInfo.panCard) {
        // Update PAN via API
        await axios.patch(
          `https://haartistaloud-backend.kollywoodhungama.com/v3/PAN/${token}`,
          { PANNo: panNumber }
        );
        toast.success("PAN number updated successfully.");
      }
      await generatePDF();
      await generateAgreementPDF();
      // Conditional logic based on the presence of agreementDate
      // if (!agreementDate) {
      //   // Generate PDFs
      //   await generateAgreementPDF();
      // } else {
      //   // Returning user flow: skip signature and PDF generation
      //   console.log(
      //     "Agreement date already exists. Skipping signature saving and PDF generation."
      //   );
      // }

      // Upload PDFs
      const uploadSuccess = await uploadPDFs();

      if (uploadSuccess) {
        setPdfdataSuccess(true);
        // navigate("/iprs");
      }
    } catch (error) {
      console.error("Error during the process:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleDisagreeClick = () => {
    navigate("/upload");
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature("");
    localStorage.removeItem("signature");
  };

  const saveSignature = async () => {
    if (!showContent) {
      toast.error("Please read the main agreement before agreeing.");
      return;
    }
    if (!userInfo.panCard) {
      if (!panNumber) {
        toast.error("Please enter your PAN number before agreeing.");
        return;
      }
    }
    if (panCardError) {
      toast.error("Please Enter Valid PAN Card Number");
      return;
    }
    // if (sigCanvas.current.isEmpty() && !agreementDate) {
    if (sigCanvas.current.isEmpty()) {
      toast.error("Please provide your digital signature before agreeing.");
      return;
    }

    const dataUrl = sigCanvas.current.toDataURL("image/png"); //base64-encoded image
    setSignature(dataUrl);
    try {
      const token = sessionStorage.getItem("Token");
      const response = await axios.patch(
        `https://haartistaloud-backend.kollywoodhungama.com/v3/digitalSignature/${token}`,
        {
          digitalSign: dataUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // If token is required in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Signature saved successfully!", {
          position: "top-center",
          autoClose: 5000,
        });
      } else {
        toast.error("Failed to save signature. Please try again.");
      }
    } catch (error) {
      console.error("Error saving signature:", error);
      toast.error(
        "An error occurred while saving your signature. Please try again."
      );
    }
    // generatePDF();
    // // Conditional logic based on the presence of agreementDate
    // if (!agreementDate) {
    //   generateAgreementPDF();
    // }

    // toast.success("Signature saved", {
    //   position: "top-center",
    //   autoClose: 5000,
    // });
    // console.log(dataUrl);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    // Debug: Log the signature URL
    // console.log("Signature URL:", signature);
    // Set the border color for the entire PDF page and signature image
    const borderColor = [0, 0, 0]; // Black color for the border (RGB)

    // Capture HTML content and render it into the PDF
    const content = document.querySelector(".termsContent"); // Use the div class that contains the content

    // Set the initial y-position of the content
    let currentYPosition = 20; // Start with a top margin of 20 (this can be adjusted as needed)

    // Set PDF margins (left and right)
    const leftMargin = 15; // Adjust left margin
    const rightMargin = 15; // Adjust right margin

    // Set the page border color (for the entire page)
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]); // Set the draw color for the border

    // Add the border around the first page
    doc.rect(
      10,
      10,
      doc.internal.pageSize.width - 20,
      doc.internal.pageSize.height - 20
    ); // Add border around the page

    // First, let's add the content as a simple string with default styling
    doc.setFont("Helvetica");
    doc.setFontSize(12); // Adjust font size as per your need
    doc.setTextColor(0, 0, 0); // Black text color

    // Wrap the text and split it into lines
    const lines = doc.splitTextToSize(
      content.innerText,
      doc.internal.pageSize.width - leftMargin - rightMargin
    ); // Wrap text based on page width, accounting for left and right margins
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 6; // Reduced line spacing to make the text more compact
    const maxTextHeight = pageHeight - 40; // Max height for text (consider top and bottom margins)

    // Loop to add wrapped text line by line
    for (let i = 0; i < lines.length; i++) {
      if (currentYPosition + lineHeight > maxTextHeight) {
        doc.addPage(); // Add new page if content exceeds the page height
        currentYPosition = 20; // Reset Y-position for the new page
        // Add border to the new page
        doc.rect(
          10,
          10,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 20
        ); // Add border on new page
      }

      doc.text(lines[i], leftMargin, currentYPosition); // Adjust left margin for the text
      currentYPosition += lineHeight; // Move to next line
    }

    // Now, let's add the signature at the end (after text is done)
    if (signature) {
      const img = new Image();
      img.onload = () => {
        const imageYPosition = currentYPosition + 10; // Add some space between content and image
        const imageWidth = 100; // Width of the signature image (can be adjusted)
        const imageHeight = 50; // Height of the signature image (can be adjusted)

        // Set the background color to white (if needed)
        doc.setFillColor(255, 255, 255); // White background
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight, "F"); // Draw white background for the signature

        // Draw a black border around the signature image
        doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]); // Set border color to black
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight); // Draw the border around the image

        doc.addImage(
          img,
          "PNG",
          leftMargin,
          imageYPosition,
          imageWidth,
          imageHeight
        ); // Add image with custom dimensions
        savePDFToLocalStorage(doc, "annexure.pdf"); // Save the PDF after image is added
      };
      img.onerror = () => {
        console.error("Failed to load signature image from", signature);
      };
      img.src = signature; // Set image source, this triggers the image loading
    }
    // savePDFToLocalStorage(doc, "annexure.pdf");
    else {
      console.warn("Signature is not available.");
      doc.save("annexure.pdf");
    }
  };

  const generateAgreementPDF = () => {
    const doc = new jsPDF();

    // Set the border color for the entire PDF page and signature image
    const borderColor = [0, 0, 0]; // Black color for the border (RGB)

    // Capture HTML content and render it into the PDF
    const content = document.querySelector(".Main-Agreement"); // Select the div containing the agreement content

    // Set the initial y-position of the content
    let currentYPosition = 20; // Start with a top margin of 20 (this can be adjusted as needed)

    // Set PDF margins (left and right)
    const leftMargin = 15; // Adjust left margin
    const rightMargin = 15; // Adjust right margin

    // Set the page border color (for the entire page)
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]); // Set the draw color for the border

    // Add the border around the first page
    doc.rect(
      10,
      10,
      doc.internal.pageSize.width - 20,
      doc.internal.pageSize.height - 20
    ); // Add border around the page

    // First, let's add the content as a simple string with default styling
    doc.setFont("Helvetica");
    doc.setFontSize(12); // Adjust font size as per your need
    doc.setTextColor(0, 0, 0); // Black text color

    // Wrap the text and split it into lines
    const lines = doc.splitTextToSize(
      content.innerText,
      doc.internal.pageSize.width - leftMargin - rightMargin
    ); // Wrap text based on page width, accounting for left and right margins
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 6; // Reduced line spacing to make the text more compact
    const maxTextHeight = pageHeight - 40; // Max height for text (consider top and bottom margins)

    // Loop to add wrapped text line by line
    for (let i = 0; i < lines.length; i++) {
      if (currentYPosition + lineHeight > maxTextHeight) {
        doc.addPage(); // Add new page if content exceeds the page height
        currentYPosition = 20; // Reset Y-position for the new page
        // Add border to the new page
        doc.rect(
          10,
          10,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 20
        ); // Add border on new page
      }

      doc.text(lines[i], leftMargin, currentYPosition); // Adjust left margin for the text
      currentYPosition += lineHeight; // Move to next line
    }

    // Now, let's add the signature at the end (after text is done)
    if (signature) {
      const img = new Image();
      img.onload = () => {
        const imageYPosition = currentYPosition + 4; // Add some space between content and image
        const imageWidth = 80; // Width of the signature image (can be adjusted)
        const imageHeight = 40; // Height of the signature image (can be adjusted)

        // Set the background color to white (if needed)
        doc.setFillColor(255, 255, 255); // White background
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight, "F"); // Draw white background for the signature

        // Draw a black border around the signature image
        doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]); // Set border color to black
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight); // Draw the border around the image

        doc.addImage(
          img,
          "PNG",
          leftMargin,
          imageYPosition,
          imageWidth,
          imageHeight
        ); // Add image with custom dimensions
        savePDFToLocalStorage(doc, "agreement.pdf"); // Save the PDF after image is added
      };
      img.onerror = () => {
        console.error(
          "Failed to load signature image from Database",
          signature
        );
      };
      img.src = signature; // Set image source, this triggers the image loading
    }
    // savePDFToLocalStorage(doc, "agreement.pdf");
  };

  const savePDFToLocalStorage = (doc, key) => {
    const pdfData = doc.output("datauristring"); // Get the base64 data URI
    localStorage.setItem(key, pdfData);
    // console.log(`PDF saved to localStorage with key: ${key}`);
    setTimeout(() => {
      setIsSaveClicked(true);
    }, 2000);
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handlePanCardChange = (event) => {
    const value = event.target.value.toUpperCase();
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    // Handle input if the value length is 10 or less
    if (value.length <= 10) {
      setPanNumber(value);

      // If the length is exactly 10, validate the format
      if (value.length === 10) {
        if (panCardRegex.test(value)) {
          setPanCardError(""); // Clear error if the PAN format is valid
          setIsPanText(true); // Switch to text view if valid
        } else {
          setPanCardError(
            "Invalid PAN card format. It should be in this format: AAAAA1234A"
          );
          setIsPanText(false); // Keep the input mode if the format is invalid
        }
      } else {
        // If the length is less than 10, set error and keep input mode
        setPanCardError("PAN card number must be exactly 10 characters.");
        setIsPanText(false);
      }
    } else {
      // If the length exceeds 10 characters, set error and restrict further input
      setPanCardError("PAN card number must be exactly 10 characters.");
      setIsPanText(false);
    }
  };

  let uploadedFilesHashes = new Set(); // Store the hashes of previously uploaded files

  const base64ToBlob = (base64, contentType = "application/pdf") => {
    let byteCharacters = atob(base64.split(",")[1]);

    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));

    const blob = new Blob([new Uint8Array(byteNumbers)], { type: contentType });

    byteNumbers.length = 0; // Clear the byteNumbers array
    byteCharacters = null; // Optionally clear the byteCharacters string

    return blob;
  };

  const hashBase64 = (base64) => {
    // You can implement a proper hash here, for example using MD5 or SHA256
    // This is a placeholder for demonstration purposes
    return btoa(base64);
  };

  const downloadPDF = (base64PDF, fileName = "generated.pdf") => {
    const blob = base64ToBlob(base64PDF, "application/pdf");
    const blobURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobURL;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL); // Free up memory
  };

  const uploadPDFs = async () => {
    const token = sessionStorage.getItem("Token");
    const agreementPDF = localStorage.getItem("agreement.pdf");
    const annexurePDF = localStorage.getItem("annexure.pdf");

    if (!annexurePDF) {
      if (!agreementDate && !agreementPDF) {
        toast.info("Please wait and click on 'Agree' button again.");
        return false;
      }
      toast.info("Please wait and click on 'Agree' button again.");
      return false;
    }
    // If agreementDate exists, we don't need the agreementPDF
    if (!agreementDate) {
      // Hash and check if the files are already uploaded
      const agreementHash = hashBase64(agreementPDF);
      const annexureHash = hashBase64(annexurePDF);

      if (
        uploadedFilesHashes.has(agreementHash) ||
        uploadedFilesHashes.has(annexureHash)
      ) {
        toast.warn("These files have already been uploaded.");
        return false;
      }

      const uniqueSuffix = Date.now(); // Use current timestamp
      const annexureFilename = `annexure_${uniqueSuffix}.pdf`;
      const agreementBlob = base64ToBlob(agreementPDF, "application/pdf");
      const annexureBlob = base64ToBlob(annexurePDF, "application/pdf");

      const formData = new FormData();
      formData.append("annexures", annexureBlob, annexureFilename);

      try {
        const response = await fetch(
          `https://haartistaloud-backend.kollywoodhungama.com/v2/audioVideoPDF/${token}`,
          { method: "POST", body: formData }
        );

        if (response.ok) {
          const data = await response.json();
          const contentId = data?.result?.contentId;

          if (contentId) {
            uploadedFilesHashes.add(agreementHash);
            uploadedFilesHashes.add(annexureHash);
            localStorage.setItem("contentId", contentId);

            try {
              const manualDate = "2023-06-08";
              const uniqueSuffix = Date.now(); // Use current timestamp
              const agreementFilename = `agreement_${uniqueSuffix}.pdf`;
              const annexureFilename = `annexure_${uniqueSuffix}.pdf`;

              const agreementFormData = new FormData();
              agreementFormData.append(
                "agreement",
                agreementBlob,
                agreementFilename
              ); // Use unique filename
              // agreementFormData.append("agreementDate", manualDate); // Use the manually set date
              // agreementFormData.append(
              //   "agreement",
              //   agreementBlob,
              //   "agreement.pdf"
              // );
              agreementFormData.append("agreementDate", formattedDateISO);
              // agreementFormData.append("agreementDate", manualDate);

              const agreementResponse = await fetch(
                `https://haartistaloud-backend.kollywoodhungama.com/v3/addAgreementDate/${token}`,
                { method: "PUT", body: agreementFormData }
              );

              if (agreementResponse.ok) {
                toast.success("Data added successfully!");
                const agreementData = await agreementResponse.json();
                toast.success("You're all set!");

                const blobDownload = (blob, fileName) => {
                  const blobURL = URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = blobURL;
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(blobURL);
                };

                // blobDownload(agreementBlob, "agreement_uploaded.pdf");
                // blobDownload(annexureBlob, "annexure_uploaded.pdf");
                // Optionally download the PDF files after successful upload
                // downloadPDF(agreementPDF, "agreement.pdf");
                // downloadPDF(annexurePDF, "annexure.pdf");

                localStorage.removeItem("agreement.pdf");
                localStorage.removeItem("annexure.pdf");
                return true;
              } else {
                const errorData = await agreementResponse.json();
                console.error("Failed to add agreement date:", errorData);
                toast.error(
                  "Failed to upload Agreement. Please save your signature and click on 'Agree' button again."
                );
              }
            } catch (error) {
              console.error("Error adding agreement date:", error);
              toast.error("Error during agreement date update.");
              return false;
            }

            return true;
          } else {
            toast.warn(
              "Error while uploading Agreement. Please click on 'Agree' button again."
            );
            return false;
          }
        } else {
          const errorData = await response.json();
          console.error("Failed to upload PDFs:", errorData);
          toast.error("Failed to upload Agreement. Please try again later.");
          return false;
        }
      } catch (error) {
        console.error("Error during PDF upload:", error);
        toast.error("Error during PDF upload.");
        return false;
      }
    } else {
      const agreementHash = hashBase64(agreementPDF);
      const annexureHash = hashBase64(annexurePDF);

      if (
        uploadedFilesHashes.has(agreementHash) ||
        uploadedFilesHashes.has(annexureHash)
      ) {
        toast.warn("These files have already been uploaded.");
        return false;
      }

      const uniqueSuffix = Date.now(); // Use current timestamp
      const annexureFilename = `annexure_${uniqueSuffix}.pdf`;
      // const agreementBlob = base64ToBlob(agreementPDF, "application/pdf");
      const annexureBlob = base64ToBlob(annexurePDF, "application/pdf");

      const formData = new FormData();
      formData.append("annexures", annexureBlob, annexureFilename);

      try {
        const response = await fetch(
          `https://haartistaloud-backend.kollywoodhungama.com/v2/audioVideoPDF/${token}`,
          { method: "POST", body: formData }
        );

        if (response.ok) {
          const data = await response.json();
          const contentId = data?.result?.contentId;

          if (contentId) {
            // uploadedFilesHashes.add(agreementHash);
            uploadedFilesHashes.add(annexureHash);
            toast.success("You're all set file uploaded!");
            localStorage.setItem("contentId", contentId);
            return true;
          } else {
            toast.warn(
              "Error while uploading Agreement. Please click on 'Agree' button again."
            );
            return false;
          }
        } else {
          const errorData = await response.json();
          console.error("Failed to upload PDFs:", errorData);
          toast.error("Failed to upload Agreement. Please try again later.");
          return false;
        }
      } catch (error) {
        console.error("Error during PDF upload:", error);
        toast.error("Error during PDF upload.");
        return false;
      }
    }
  };

  // useEffect(() => {
  //   console.log("Updated Date:", formattedDateISO); // Log state changes
  //   console.log("User Data:", userInfo);
  // }, [userInfo]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = sessionStorage.getItem("Token");
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/T&CData/${token}`
        );

        if (response.data.status === 200) {
          const userData = response.data.data[0];
          setUserInfo(userData);

          const signatureUrl = userData.signature.replace(
            "https://storage.cloud.google.com",
            "https://storage.googleapis.com"
          );

          // console.log("Fetching signature Blob from URL:", signatureUrl);

          if (signatureUrl) {
            const blobUrl = await fetchImageAsBlob(signatureUrl, true);
            // console.log("Blob URL:", blobUrl);
            setSignature(blobUrl);
            localStorage.setItem("signature", blobUrl);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Helper function to fetch the image as a Blob and handle CORS
  const fetchImageAsBlob = async (url, enableCors = false) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: enableCors ? "cors" : "no-cors", // Highlight: Use CORS mode
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch image. Status: ${response.status}`);
      }

      const blob = await response.blob();
      return URL.createObjectURL(blob); // Convert Blob to Object URL
    } catch (error) {
      console.error("Error fetching image as Blob:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchAgreementDate = async () => {
      const token = sessionStorage.getItem("Token");
      try {
        // GET API call to fetch the agreement date
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/getAgreementDate/${token}`
        );

        if (response.data.status === 200 && response.data.agreementDate) {
          setAgreementDate(response.data.agreementDate); // Set agreement date if present
        } else {
          // If no agreement date, call the PATCH API
          setAgreementDate(formattedDateISO); // Update state with the current formatted date
        }
      } catch (error) {
        console.error("Error fetching agreement date:", error);
      }
    };

    fetchAgreementDate();
  }, []);

  const delayedNavigate = () => {
    setTimeout(() => {
      if (showIprs) {
        navigate("/iprs");
      } else {
        navigate("/attach-audio");
      }
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div className="container1">
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md">
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          {showIprs && (
            <>
              <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
                <p>IPRS</p>
              </div>
              <br />
            </>
          )}
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && <p>Attach Audio</p>}
            {contentType === "video" && <p>Attach Video</p>}
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className="mt-[4%] flex flex-col max-w-[70%]">
          <div className=" py-4 overflow-y-scroll termsContent bg-[white] rounded-md text-black">
            <div className="flex justify-center">
              <h2
                className="  text-[0.9rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "300",
                }}
              >
                ANNEXURE TO ACKNOWLEDGE THE ADDITION OF THE TRACK TO THE
                AGREEMENT
              </h2>
            </div>
            <br />
            <br />
            <div className="flex flex-col justify-center ml-[12%]">
              <h2
                className=" text-[1rem] text-black"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                }}
              >
                Licensor: <span>{licensorName}</span>
              </h2>
              <br />
              <h2
                className=" text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                }}
              >
                Artist Name: <span>{artistName}</span>
              </h2>
              <br />
              <h2
                className=" text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                }}
              >
                Album Name: <span>{albumName}</span>
              </h2>
              <br />
              <h2
                className=" text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                }}
              >
                Track Name: <span>{trackName}</span>
              </h2>
              <br />
              <h2
                className=" text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                }}
              >
                Date of Execution: <span>{dateOfExecution}</span>
              </h2>
              <br />
            </div>
            <div className="flex justify-center">
              <h2
                className=" text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "300",
                  textDecorationLine: "underline",
                }}
              >
                To Whomsoever It May Concern
              </h2>
            </div>
            <br />
            <div
              className=" text-[0.9rem] mx-[10%] p-[10px]"
              style={{
                maxWidth: "100%",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "200",
                textAlign: "justify",
                // backgroundColor: "#ffffff0d",
              }}
            >
              <h3>
                This is to certify that by virtue of arrangement between faf and
                we have granted Hungama the exclusive Rights to the Audio /
                Video Content mentioned inAnnexure A including future content
                during the term of agreement to this letter. The rights are
                licensed for period of on an auto renewal basis commencing
                from 10th July 2024 (‘Effective Date”) until terminated by
                either Party.
              </h3>
              <br />
              <h3>
                Hungama as an agent shall have the rights to sub-license,
                reformat, perform, publish, display, transmit, copy, store,
                stream, rent, hire, embed, retail, offer for subscription,
                downloading, streaming and/or marketing, exploiting &
                distributing the content (Licensed Content) on all digital
                platforms including Virtual Reality unless specifically excluded
                by agreement (which are not limited to Mobile, Internet, Hungama
                platform and its affiliates ) Hungama may exercise the licensed
                rights in respect of Licensed Content in the territory of
                the World. This annexure shall include existing as well as
                future Hungama platforms.
              </h3>
              <br />
              <h3>
                The Artist / Licensor has full right, title and interest in and
                to all Intellectual Property Rights in the Licensed Content and
                is legally authorized and entitled to provide the royalty free
                Licensed Content to the Licensee for exploiting the same as
                provided in this Agreement. Artist/ Licensor represents and
                warrants that the Licensee shall be entitled to exploit the
                Licensed Content without any obstruction or interference from
                any third party.
              </h3>
              <br />
              <h3>
                The Artist / Licensor indemnifies and hold harmless the Licensee
                from and against any loss, injury or damage caused to Licensee
                in consequence of any of the breach of warranties or arising out
                of any claim alleging that the use of the Content constitutes in
                any way a breach of any warranties or rights of other parties,
                including but not limited to alleged or actual infringement of
                intellectual property rights who may have claim over the said
                Content. The indemnification shall include any legal costs,
                punitive or compensatory awards or expenses and disbursements
                paid by Licensee on advice of its legal counsel to compromise or
                settle any claim.
              </h3>
              <br />
              <h3>
                This Annexure is an addition to the Agreement and all other
                terms and conditions of the Agreement shall remain in full force
                and effect.
              </h3>
            </div>
          </div>
          <div>
            <a
              className="text-white text-[0.9rem] p-[30px]"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "300",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={toggleContent}
              clickable
            >
              {showContent ? "Hide Main Agreement" : "Show Main Agreement"}
            </a>
          </div>

          {showContent && (
            <div className="Main-Agreement overflow-y-scroll termsContent flex flex-col p-[30px] text-black bg-[white] rounded-md">
              <div className="flex justify-center">
                <h1
                  className=" text-[1.8rem]"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  AGREEMENT
                </h1>
              </div>
              <div>
                <div>
                  <br />
                  <br />
                  <h2
                    className=" text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                    }}
                  >
                    This agreement was made on:{" "}
                    {agreementDate || formattedDateISO}
                  </h2>
                  <br />
                  <br />
                  <table
                    style={{
                      border: "1px solid black",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr style={{ border: "1px solid black" }}>
                      <th
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        Artist / Licensor:
                      </th>
                      <td
                        className=" text-[0.9rem] w-[70%]"
                        style={{
                          border: "1px solid black",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        {userInfo.artistName || licensorName}
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid black" }}>
                      <th
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        Address:
                      </th>
                      <td
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        {userInfo.address ? (
                          <>
                            <div>
                              {userInfo.address}, {userInfo.city},{" "}
                              {userInfo.state}, {userInfo.country} -{" "}
                              {userInfo.zipcode}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              {userInfo.city}, {userInfo.state},{" "}
                              {userInfo.country} - {userInfo.zipcode}
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid black" }}>
                      <th
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        Mobile No.:
                      </th>
                      <td
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        {userInfo.mobileNo}
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid black" }}>
                      <th
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        Email:
                      </th>
                      <td
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        {userInfo.email}
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid black" }}>
                      <th
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        PAN No.:
                      </th>
                      <td
                        className=" text-[0.9rem]"
                        style={{
                          border: "1px solid black",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        {userInfo.panCard ? (
                          <span
                            style={{
                              display: "block",
                              backgroundColor: "transparent",
                              color: "black",
                              padding: "0.3rem",
                              borderRadius: "4px",
                              textAlign: "center",
                            }}
                          >
                            {userInfo.panCard}
                          </span>
                        ) : isPanText ? (
                          <span
                            style={{
                              display: "block",
                              backgroundColor: "transparent",
                              color: "black",
                              padding: "0.3rem",
                              borderRadius: "4px",
                              textAlign: "center",
                            }}
                          >
                            {panNumber}
                          </span>
                        ) : (
                          <input
                            type="text"
                            placeholder="Enter your PAN No."
                            value={panNumber}
                            onChange={handlePanCardChange}
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "4px",
                              padding: "0.3rem",
                              textAlign: "center",
                              width: "50%",
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  </table>
                  {panCardError && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {panCardError}
                    </div>
                  )}
                </div>
                <br />
                <div>
                  <h1
                    className=" text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Licensee: Hungama Digital Media Entertainment Pvt Ltd
                  </h1>
                  <br />
                  <h1
                    className=" text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Address: SF-B-07, Art Guild House, Phoenix Market City, LBS
                    Marg, Kurla (W), Mumbai, Maharashtra 400 070.
                  </h1>
                  <br />
                  <h1
                    className=" text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Term & Conditions
                  </h1>
                </div>
                <br />
                <div
                  className=" text-[0.9rem]"
                  style={{
                    maxWidth: "100%",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "200",
                    textAlign: "justify",
                    // backgroundColor: "#ffffff0d",
                  }}
                >
                  <h3 className="">
                    The Artist / Licensor hereby agrees to be bound by and
                    subject to the following terms & conditions which comprises
                    a legally binding agreement between the Artist / Licensor
                    and Hungama Digital Media Entertainment Pvt Ltd:
                  </h3>
                  <br />
                  <ul className=" mx-[5%]">
                    <li>
                      The Artist / Licensor represents and warrants that it is
                      the owner and / or has the necessary rights in the content
                      licensed (hereinafter “Licensed Content“) to Hungama
                      Digital Media Entertainment Pvt. Ltd. (hereinafter
                      referred to as the “Licensee“) to grant the said License.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor hereby grants an non-exclusive,
                      sub-licensable, irrevocable license to distribute, exploit
                      and market the License Content on the Distribution Channel
                      and make the same available to the end users for
                      monetization and offer the Licensed Content for
                      sub-licensing, download, streaming, renting, hiring,
                      retail, subscription in any form or manner now known or
                      hereafter devised and/ or invented in future. Hungama
                      shall distribute the Licensed Content exclusively on the
                      Distribution Channels that has been informed and further
                      shall be informed via email in case of future additions,
                      which shall form an integral part of this Agreement.
                    </li>
                    <br />
                    <li>
                      The 'Distribution Channel' shall mean the modes of
                      distribution of the Licensed Content through Hungama and
                      all its affiliates and other platforms including but not
                      limited to Mobile (including IVR and WAP), Application,
                      DTH, Linear TV, Virtual reality, WAP and Website, Podcast
                      services, OTT Services; In-flight Entertainment,
                      terrestrial radio etc. The Licensee shall also have the
                      right to exploit the Licensed Content through its
                      affiliates in the territory of World during the Term of
                      this Agreement. This agreement shall include existing as
                      well as future Hungama platforms.
                    </li>
                    <br />

                    <li>
                      Existing content of the Artist / Licensor as well as
                      future content produced by the Artist / Licensor shall be
                      covered within the ambit of this Agreement. Details of
                      such content shall be added with an email approval from
                      the Artist/ licensor and the same shall form integral part
                      of this Agreement.
                    </li>
                    <br />
                    <li>
                      The term shall be effective for a period of Three (3)
                      years commencing from 19th April 2024 (‘Effective Date”)
                      unless otherwise earlier terminated as per provisions of
                      this Agreement.
                    </li>
                    <br />
                    <li>
                      The licensor represents and warrants that the exploitation
                      of the rights herein and the underlying works forming part
                      of the Content shall not infringe the copyright (including
                      the moral rights of the authors of the underlying works),
                      right of privacy or any other rights whatsoever of any
                      person. Further, the Licensor agrees that the provisions
                      of Section 19(4), 19A and 30A of the Copyright Act, 1957
                      (as amended) shall not apply to this Agreement and the
                      Licensor hereby waives its rights under the aforesaid
                      provisions of the said Act.
                    </li>
                    <br />
                    <li>
                      The Licensee shall share 70% of Net Revenue generated from
                      the exploitation of the Licensed Content with the Artist /
                      Licensor. Net Revenue shall mean End User Price less
                      either Bandwidth Cost or Operator Share (as may be
                      applicable) after deduction of Taxes. For streaming of the
                      Licensed Content on Hungama Music, the Licensee shall pay
                      on a per stream basis and the same will be displayed in
                      the report sent to the Artist/Licensor.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor acknowledges that no royalty payment
                      and/or aggregation charges shall be paid by the Licensee
                      for the Licensed Content used for promotion purpose and
                      for the content which is streamed for free.
                    </li>
                    <br />
                    <li>
                      Licensee shall provide quarterly reports of exploitation
                      of Licensed Content the Artist / Licensor. Based on the
                      reports, the Artist / Licensor shall raise an invoice for
                      its share of Net Revenue on the Licensee. Net Revenue
                      shall be paid to the Licensor if such Net Revenue share of
                      Licensor exceeds minimum Rs.1,500/- (Rupees One Thousand
                      and Five Hundred Only) for Artist based in India and
                      minimum $1000 (USD One Thousand Only) for Artist based
                      outside of India. This payment clause will have exception
                      where the Agreement is terminated or expires, Hungama is
                      obliged to pay the implied outstanding amount to the
                      Licensor.
                    </li>
                    <br />
                    <li>
                      Artist / Licensor agrees that the Net Revenue share
                      received by it from the Licensee vide this Agreement is
                      inclusive of royalties and publishing fees. Licensee shall
                      not be liable to pay any royalties, fees or any other
                      amount to the Artist / Licensor or to any music composer,
                      lyricist, performer or any third party for exploiting the
                      Licensed Content. Any such payment to the third Party
                      shall be the sole and non-exclusive responsibility of the
                      Artist / Licensor.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor has full right, title and interest
                      in and to all Intellectual Property Rights in the Licensed
                      Content and is legally authorized and entitled to provide
                      the royalty free Licensed Content to the Licensee for
                      exploiting the same as provided in this Agreement. Artist/
                      Licensor represents and warrants that the Licensee shall
                      be entitled to exploit the Licensed Content without any
                      obstruction or interference from any third party.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor has obtained the necessary and valid
                      license and all other approvals, consents, permissions
                      etc.; which shall be kept in force and valid, and to
                      provide the Content contemplated herein which includes
                      compliance of statutory and non-statutory requirements and
                      obligations and all other digital management rights,
                      various issues and obligations to protect the Intellectual
                      Property Rights.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor indemnifies and hold harmless the
                      Licensee from and against any loss, injury or damage
                      caused to Licensee in consequence of any of the breach of
                      warranties or arising out of any claim alleging that the
                      use of the Content constitutes in any way a breach of any
                      warranties or rights of other parties, including but not
                      limited to alleged or actual infringement of intellectual
                      property rights who may have claim over the said Content.
                      The indemnification shall include any legal costs,
                      punitive or compensatory awards or expenses and
                      disbursements paid by Licensee on advice of its legal
                      counsel to compromise or settle any claim.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor warrants that the Content is fault
                      free and that service is uninterrupted. In the event of
                      any Content being unlawful, harmful, threatening, abusive,
                      harassing, tortuous, defamatory, vulgar, libellous,
                      invasive of another's privacy, hateful, or racially,
                      ethnically, or otherwise objectionable, or that expresses
                      cruelty, violence, or inhumanity and/or any Content
                      contains pornography, obscenity, or grossly insulting
                      message, or is vulgar and obscene the Licensee shall not
                      be held liable for the same.
                    </li>
                    <br />
                    <li>
                      Licensee shall be entitled to terminate this Agreement
                      without any reason by giving thirty (30) days prior notice
                      in writing to the Licensor. Either Party can terminate
                      this Agreement by giving written 30 days' notice to the
                      other party due to breach of any terms of this Agreement
                      by other Party. If such breach is not cured within thirty
                      (30) days of receipt of notice by the defaulting Party,
                      then the Party not in default may terminate this
                      Agreement.
                    </li>
                    <br />
                    <li>
                      Unless otherwise stated in writing sixty (60) days prior
                      to the expiry of the term, the Agreement will be deemed to
                      be automatically renewed on its expiry of the Term of this
                      Agreement and on same terms and condition as defined in
                      this Agreement.
                    </li>
                    <br />
                    <li>
                      The Parties will endeavor to settle amicably by mutual
                      discussions any disputes, differences, or claims
                      whatsoever related to this Agreement. The Hon’ble Courts
                      in Mumbai shall have the non-exclusive jurisdiction over
                      the disputes arising out of this Agreement between the
                      parties hereto.
                    </li>
                    <br />
                    <li>
                      Artist shall not be entitled to assign this
                      Agreement/Licensed Content without obtaining prior written
                      consent of the Licensee.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {userInfo.signature ? (
            <div
              className="sigImageContainer bg-white rounded-md my-6"
              style={{ width: 500, height: 200 }}
            >
              <img
                src={userInfo.signature}
                alt="Signature"
                className="signatureImage"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          ) : (
            <div className="mx-[12%] my-[20px]">
              <h2 className="text-[#FFFFFF] text-[1rem]">Digital Signature:</h2>
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{
                  width: 500,
                  height: 200,
                  backgroundColor: "white",
                  className: "sigCanvas",
                }}
                penColor="black"
              />

              <div className="flex mt-[10px]">
                <button
                  className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px]"
                  onClick={clearSignature}
                >
                  Clear
                </button>
                <button
                  className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px] ml-[10px]"
                  onClick={saveSignature}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          <div
            className="mb-[5%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            {/* <div className="flex justify-start mt-[1%]"> */}
            {!pdfdataSuccess ? (
              <>
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF]  hover:bg-[#FF6C2F] active:bg-[#c75525]"
                  onClick={handleAgreeClick}
                  // disabled={!isSaveClicked}
                  // style={{
                  //   backgroundColor: !isSaveClicked ? "#9e9e9e" : "#302F2F", // Disabled color
                  //   cursor: !isSaveClicked ? "not-allowed" : "pointer", // Cursor change
                  //   opacity: !isSaveClicked ? 0.6 : 1, // Reduced opacity when disabled
                  // }}
                >
                  Agree
                </button>
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#484848] active:bg-[#484848]"
                  onClick={handleDisagreeClick}
                >
                  Disagree
                </button>
              </>
            ) : (
              <button
                className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#FF6C2F] active:bg-[#c75525]"
                onClick={delayedNavigate}
              >
                Next
              </button>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
