import React from "react";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  return (
    <>
      <div className="bg-[#000]">
        <div className="flex flex-col md:flex-row justify-center items-center space-x-30 gap-10 pb-5">
          <div className="flex flex-col justify-center items-center md:block">
            <div className="pt-11 flex flex-col justify-center items-center md:block">
              <h2 className="text-[#FF6C2F] text-base font-semibold">
                COMPANY
              </h2>
              <div className="text-white text-base font-medium md:pt-4">
                <a href={publicRouteLink.ABOUT_US}>
                  About Us
                </a>
              </div>
              <div className="text-white text-base font-medium">
                <a href={publicRouteLink.TERMS_AND_CONDITIONS}>
                  Terms & Conditions
                </a>
              </div>
              <div className="text-white text-base font-medium">
                <a href={publicRouteLink.PRIVACY_POLICY}>
                  Privacy Policy
                </a>
              </div>
              {/* <div className="text-white text-base font-medium">
                <a href="https://www.hungamaartistaloud.com/privacy-policy.php">
                  Grievance Officer
                </a>
              </div> */}
            </div>

            <div className="pt-7 flex flex-col justify-center items-center md:block">
              <h2 className="text-[#FF6C2F] text-base font-semibold">
                CONTACT US
              </h2>
              <div className="text-white text-base font-medium break-words pt-4 w-80 md:!text-left sm:!text-center">
                artistaloud@hungama.com
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center space-y-6 md:pl-10 pl-0 ">
            {/* <div className="text-white text-3xl font-bold sm:break-words sm:text-xl">
              HUNGAMA ARTIST ALOUD
            </div> */}
            <img
              className="w-20 h-20"
              src="\assets\HungamaLogo.png"
              alt="logo"
            />
            <div className=" text-white text-3xl font-medium break-words  sm:text-xl">
              AN INITIATIVE BY
            </div>
            <img className="" src="assets\hungama-logo.png" alt="logo" />
          </div>

          <div className="flex flex-col md:pl-52 pl-0">
            <div className="pt-0">
              <h2 className=" text-[#FF6C2F] text-base font-semibold">
                CONNECT WITH US
              </h2>
              <div className="flex flex-row space-x-4 pt-6 ">
                <a
                  href="https://www.instagram.com/artistaloud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets\instagram.png" alt="insta-logo" />
                </a>
                <a
                  href="https://www.facebook.com/artistaloud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets\facebook.png" alt="fb-logo" />
                </a>

                <a
                  href="https://www.youtube.com/artistaloud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets\youtube.png" alt="utube-logo" />
                </a>
                <a
                  href="https://twitter.com/artistaloud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets\Vector.svg" alt="twitter-logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-center bg-black text-white pb-7">
        <Link to={publicRouteLink.TERMS_AND_CONDITIONS}>
          <span className="text-base font-medium underline">
            Terms & Conditions
          </span>
        </Link>
        <span className="text-base font-medium"> | </span>
        <Link to={publicRouteLink.PRIVACY_POLICY}>
          <span className="text-base font-medium underline">
            Privacy policy
            <br />
          </span>
        </Link>
        <span className="text-base font-medium">
          All rights reserved. Copyrights © {currentYear}-{nextYear} Hungama
          Digital Media Ent. Pvt. Ltd.
        </span>
      </div>
    </>
  );
};

export default Footer;
