
export const publicRouteLink = {
    HOME: '/',
    CONTACT_US: '/contact-us',
    ABOUT_US: '/about-us',
    SIGNIN: '/sign-in',
    SIGNUP: '/sign-up',
    COMPLETE_YOUR_ACCOUNT: '/complete-your-account',
    COMPLETE_YOUR_PROFILE: '/complete-your-profile',
    UPLOADS: '/upload',
    MARKETING_PLANS: '/marketing-plans',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    OTP_VERIFICATION: '/otp-verification',
    CHANGE_PHONENUMBER: '/change-phoneNumber',
    DASHBOARD: '/dashboard',
    RELEASES: '/releases',
    ROYALTY_REPORT: '/royalty-report',
    INVOICE: '/invoice',
    ACCOUNT_DETAILS: '/account-details',
    SOCIAL_MEDIA: '/social-media',
    BANK_DETAILS: '/bank-details',
    DETAILS: '/details-audio',
    CONTENT_DISTRIBUTION: '/content-distribution',
    TERMS_UPLOADS: '/terms-uploads',
    IPRS: '/iprs',
    ATTACH_FILE: '/attach-audio',
    COVER_IMAGE: '/cover-image',
    UPLOAD_SUBMISSION: '/upload-submission',
    MARKETING_PLANS: '/marketing-plans',
    INDEPENDENT_TOGETHER: 'https://tickets.hungamaartistaloud.com/reg-events/gjdgye-independent-together'
}