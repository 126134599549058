import React, { useState, useEffect } from "react";

export default function Popup({ isOpen, onClose, contentIdForSong }) {
  const [selectedSubGenres, setSelectedSubGenres] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [isExplicit, setIsExplicit] = useState(0);
  const [releaseDate, setReleaseDate] = useState("");

  useEffect(() => {
      console.log("Received contentIdForSong in edit popup:", contentIdForSong);
    }, [contentIdForSong]);

  const handleOk = () => {
    onClose();
  };
  if (!isOpen) return null;

  const handleLanguageToggle = (language) => {
    setSelectedLanguages((prev) => {
      const newLanguages = prev.includes(language)
        ? prev.filter((item) => item !== language)
        : [...prev, language];
      //   saveToLocalStorage(newLanguages);
      return newLanguages;
    });
  };

  const handleGenreToggle = (genre) => {
    setSelectedGenres((prev) => {
      const newGenres = prev.includes(genre)
        ? prev.filter((item) => item !== genre)
        : [...prev, genre];
      //   saveToLocalStorage(newGenres);
      return newGenres;
    });
  };

  const handleSubGenreToggle = (subGenre) => {
    setSelectedSubGenres((prev) => {
      const newSubGenres = prev.includes(subGenre)
        ? prev.filter((item) => item !== subGenre)
        : [...prev, subGenre];
      //   saveToLocalStorage(newSubGenres);
      return newSubGenres;
    });
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords((prev) => {
      const newKeyword = prev.includes(keyword)
        ? prev.filter((item) => item !== keyword)
        : [...prev, keyword];
    //   saveToLocalStorage(newKeyword);
      return newKeyword;
    });
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-70">
        <div className="bg-[#555555] p-4 rounded-md w-full max-w-screen-lg h-[60vh] overflow-x-auto">
          <table className="w-full min-w-max bg-gray-700 text-white border border-gray-600 rounded-md">
            <thead className="bg-[#252525]">
              <tr>
                <th className="w-[180px] autnh-1 rounded-l-md border-r border-gray-600">
                  LANGUAGE
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">GENRE</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">SUB GENRE</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">MOOD</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">LYRICIST</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  MUSIC DIRECTOR
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">SINGER</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">PRODUCER</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  DESCRIPTION
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">TAGS</th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">OTHERS</th>
                {/* <th className="w-[180px] autnh-1 border-r border-gray-600">
                  COPY RIGHTS
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  PUBLISHING RIGHTS
                </th> */}
                <th className="w-[220px] autnh-1 border-r border-gray-600">KEYWORDS</th>
                {/* {props.type === "video" && ( */}
                <th className="w-[180px] autnh-1 border-r border-gray-600">RATINGS</th>
                {/* )} */}
                <th className="w-[180px] autnh-1 rounded-r-md">TRACK NAME</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-0.5 bg-transparent"></tr>
            </tbody>
            <tbody>
              <tr>
                <td className="autnr rounded-l-md border-r border-gray-600">
                  <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Hindi",
                      "Telugu",
                      "Tamil",
                      "Bengali",
                      "Gujarati",
                      "Malayalam",
                      "Odia",
                      "Urdu",
                      "Kannada",
                      "Sanskrit",
                      "Marathi",
                      "Assamese",
                      "Punjabi",
                      "Nepali",
                      "Konkani",
                      "Maithili",
                      "Dogri",
                      "Sindhi",
                      "Santali",
                      "Manipuri",
                      "Bhojpuri",
                      "Arabic",
                      "Meitei",
                      "Chattisgiri",
                      "Haryanvi",
                    ].map((language) => (
                      <div key={language} className="flex items-center">
                        <input
                          type="checkbox"
                          id={language}
                          value={language}
                          checked={selectedLanguages.includes(language)}
                          onChange={() => handleLanguageToggle(language)}
                          className="mr-2"
                        />
                        <label htmlFor={language}>{language}</label>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="autnr border-r border-gray-600">
                  <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Acoustic",
                      "Anthem",
                      "Bhajan",
                      "Bhangra",
                      "Bharatanatyam",
                      "Bhavageete",
                      "Blues",
                      "Carnatic music",
                      "Chaiti",
                      "Classical",
                      "Comedy",
                      "Contemperory",
                      "Country",
                      "Dadra",
                      "Devotional",
                      "Devotional song",
                      "Dhamar",
                      "Dhrupad",
                      "EDM",
                      "Filmi",
                      "Filmi qawwali",
                      "Folk music",
                      "Funk",
                      "Fusion",
                      "Gaana",
                      "Ghazal",
                      "Hard Rock",
                      "Haveli Sangeet",
                      "Hindi cinema",
                      "Hindustani classical music",
                      "Hip-Hop",
                      "Hori",
                      "Indian hip hop",
                      "Indian pop",
                      "Indian rock",
                      "Inspirational",
                      "Khyal",
                      "Light music",
                      "Mand",
                      "Music in ancient India",
                      "Music of Punjab",
                      "Music of Rajasthan",
                      "Odissi music",
                      "Patriotic",
                      "Poetry",
                      "Pop",
                      "Qawwali",
                      "R&B",
                      "Rabindra Sangeet",
                      "Raga",
                      "Raga rock",
                      "Rap",
                      "Rock",
                      "Romantic",
                      "Sadra",
                      "Semi-classical music",
                      "Sufi",
                      "Sufi music",
                      "Sufi rock",
                      "Sugama Sangeetha",
                      "Tappa",
                      "Tarana",
                      "Thumri",
                      "Trance",
                      "Trap",
                      "World music",
                    ].map((genre) => (
                      <div key={genre} className="flex items-center">
                        <input
                          type="checkbox"
                          id={genre}
                          value={genre}
                          checked={selectedGenres.includes(genre)}
                          onChange={() => handleGenreToggle(genre)}
                          className="mr-2"
                        />
                        <label htmlFor={genre}>{genre}</label>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="autnr border-r border-gray-600">
                <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                {[
                  "Aarti",
                  "Abhang",
                  "Acoustic",
                  "Acoustic Pop",
                  "Alternative",
                  "Alternative Metal",
                  "Alternative Rock",
                  "Anthem",
                  "Ashaar",
                  "Ballad",
                  "Bhajan",
                  "Bhakti Sangeet",
                  "Bheem Geet",
                  "Blues",
                  "Bollywood",
                  "Carnatic",
                  "Carols",
                  "Chants",
                  "Children",
                  "Christian",
                  "Classic Rock",
                  "Classical",
                  "Comedy",
                  "Contemporary",
                  "Country",
                  "Dance",
                  "Dance Pop",
                  "Devotional",
                  "Dharmik Sangeet",
                  "Disco",
                  "Discourse",
                  "Dohe",
                  "Dua",
                  "Dubstep",
                  "Easy Listening",
                  "EDM",
                  "Electro",
                  "Electro House",
                  "Electronic Trap",
                  "Electronica",
                  "Experimental",
                  "Festival",
                  "Folk",
                  "Folk Rock",
                  "Funk",
                  "Funny",
                  "Fusion",
                  "Ghazal",
                  "Gospel",
                  "Grunge",
                  "Guru Bani",
                  "Hadith Sharif",
                  "Hamd",
                  "Hard Rock",
                  "Hindustani",
                  "Hip hop",
                  "Hollywood",
                  "House",
                  "Hymns",
                  "Indie",
                  "Indie Pop",
                  "Indie Rock",
                  "Inspirational",
                  "Interview",
                  "Islamic",
                  "Jazz",
                  "Kirtan",
                  "Lavani",
                  "Lokgeet",
                  "Lounge",
                  "Mantras",
                  "Meditation",
                  "Mersia",
                  "Mixtape",
                  "Motivational",
                  "Movie Soundtracks",
                  "Mushaira",
                  "Naad",
                  "Naats",
                  "Narration",
                  "Natak",
                  "New Age",
                  "Nonstop",
                  "Novelty",
                  "Oldies",
                  "Patriotic",
                  "Peaceful",
                  "Pop",
                  "Qawwali",
                  "Rap",
                  "Reggae",
                  "Remix",
                  "Rock",
                  "Romantic",
                  "Sanskrit",
                  "Sufi",
                  "Sound Effects",
                  "Speech",
                  "Spiritual",
                  "Sports",
                  "Storytelling",
                  "Talas",
                  "Talk",
                  "Techno",
                  "Themed Music",
                  "Traditional",
                  "Trance",
                  "Vedic",
                  "Wedding",
                  "World",
                ].map((subGenre) => (
                  <div key={subGenre} className="flex items-center">
                    <input
                      type="checkbox"
                      id={subGenre}
                      value={subGenre}
                      checked={selectedSubGenres.includes(subGenre)}
                      onChange={() => handleSubGenreToggle(subGenre)}
                      className="mr-2"
                    />
                    <label htmlFor={subGenre}>{subGenre}</label>
                  </div>
                ))}
              </div>
                </td>

                <td className="autnr border-r border-gray-600">
                  <select
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                    style={{ outlineStyle: "none" }}
                  >
                    <option value="Happy">Happy</option>
                    <option value="Sad">Sad</option>
                    <option value="Exuberant">Exuberant</option>
                    <option value="Energetic">Energetic</option>
                    <option value="Frantic">Frantic</option>
                    <option value="Anxious">Anxious</option>
                    <option value="Calm">Calm</option>
                    <option value="Contentment">Contentment</option>
                  </select>
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                {/* <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td> */}
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Themes and messages",
                      "Violence",
                      "Nudity",
                      "Sex",
                      "Language",
                      "Drug and substance abuse",
                      "Horror",
                    ].map((keyword) => (
                      <div key={keyword} className="flex items-center">
                        <input
                          type="checkbox"
                          id={keyword}
                          value={keyword}
                          checked={selectedKeywords.includes(keyword)}
                          onChange={() => handleKeywordToggle(keyword)}
                          className="mr-2"
                        />
                        <label htmlFor={keyword}>{keyword}</label>
                      </div>
                    ))}
                  </div>
                </td>

                {/* {props.type === "video" && ( */}
                <td className="autnr border-r border-gray-600">
                  <select
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                    style={{ outlineStyle: "none" }}
                  >
                    <option
                      className="hover:bg-[#FF6C2F] active:bg-[#c75525]"
                      value="U"
                    >
                      U
                    </option>
                    <option value="7+">7+</option>
                    <option value="13+">13+</option>
                    <option value="16+">16+</option>
                    <option value="18+">18+</option>
                  </select>
                </td>
                {/* )} */}

                <td className="autnr rounded-r-md">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-end mt-[5vh]">
            <button
              className="bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#FF6C2F] active:bg-[#c75525] p-[12px]"
              onClick={handleOk}
            >
              Update
            </button>
            <button
              className=" ml-[10px] bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#252525] active:bg-[#484848] p-[12px]"
              onClick={handleOk}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
