import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useContent } from "../context/main.js";
import axios from "axios";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [imgError, setImgError] = useState(false);
  const [userName, setUserName] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const { formData, subscriptionPlan, setSubscriptionPlan } = useContent();
  console.log("formData in ProfileDropdown:", formData);

  useEffect(() => {
    const getUserInfo = async () => {
      const token = sessionStorage.getItem('Token');
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );

        if (response.status === 200) {
        const userData = response.data.data[0];

        // Log the API response
        console.log("API response data:", userData);

        setUserName(userData.full_name || "");
        setEmailValue(userData.email || "");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, []);

  const handleImageError = () => {
    setImgError(true);
  };

  const handleLogOut = () => {
    sessionStorage.removeItem("Token");
    setSubscriptionPlan("");
    // Keep formData intact, remove other items from localStorage
    // const savedFormData = localStorage.getItem("formData");
    // const subscriptionPlan = localStorage.getItem("subscriptionPlan");
    localStorage.clear();
    sessionStorage.clear();
    // if (savedFormData && subscriptionPlan) {
    //     localStorage.setItem("formData", savedFormData);
    //     localStorage.setItem("subscriptionPlan", subscriptionPlan);
    // }

    navigate("/");
};


  const getBackgroundColor = () => {
    switch (subscriptionPlan) {
      case "platinum":
        return "bg-gradient-to-r from-black via-purple-800 to-purple-800";
      case "gold":
        return "bg-gradient-to-r from-black via-yellow-600 to-yellow-800";
      case "silver":
        return "bg-gradient-to-r from-black via-sky-600 to-sky-800";
      default:
        return "bg-[#FF6C2F]";
    }
  };
  

  return (
    <div className="bg-[#252525] rounded-md shadow-sm w-64 p-4 text-white">
      {/* User Info Section */}
      <div
        // className={`flex items-center mb-4 p-2 rounded-md ${getBackgroundColor()}`}
        className={"flex items-center mb-4 p-2 rounded-md bg-[#FF6C2F]"}

      >
        {imgError ? (
          <ion-icon
            style={{ fontSize: "2rem", marginRight: "4px" }} // Adjust the size as needed
            name="person-circle-sharp"
          ></ion-icon>
        ) : (
          <img
            src="" //add image from api
            alt="User Avatar"
            className="w-10 h-10 mr-4 rounded-md"
            onError={handleImageError} // Trigger error handler if image fails to load
          />
        )}
        <div className="w-full max-w-full break-words overflow-hidden">
          <p className="text-md">{userName}</p>
          <p className="text-xs">{emailValue}</p>
        </div>
      </div>

      {/* Menu Options */}
      <ul className="space-y-2">
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </li>
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={() => navigate("/account-details")}
        >
          Profile
        </li>
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={handleLogOut}
        >
          Logout
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
