import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useContent } from "../context/main.js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Details = () => {
  const { contentType, showIprs } = useContent();

  const [artistNameValue, setArtistNameValue] = useState("");
  const [artistLegalNameValue, setArtistLegalNameValue] = useState(""); //is storing as album name in database
  const [trackNameValue, setTrackNameValue] = useState("");
  const [writerValue, setWriterValue] = useState("");
  const [directorValue, setDirectorValue] = useState("");
  const [singerValue, setSingerValue] = useState("");
  const [licensorValue, setLicensorValue] = useState("");
  const [contentDescriptionValue, setContentDescriptionValue] = useState("");
  const [tagsValue, setTagsValue] = useState("");
  const [copyRightsValue, setCopyRightsValue] = useState("");
  const [publishingRightsValue, setPublishingRightsValue] = useState("");
  const [isrcValue, setIsrcValue] = useState("");
  const [selectedSubGenres, setSelectedSubGenres] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [isExplicit, setIsExplicit] = useState(0);
  const [releaseDate, setReleaseDate] = useState("");
  const [isrcError, setIsrcError] = useState("");
  const [trackNameError, setTrackNameError] = useState("");
  const [artistNameError, setArtistNameError] = useState("");
  const [artistLegalNameError, setArtistLegalNameError] = useState("");
  const [writerError, setWriterError] = useState("");
  const [directorError, setDirectorError] = useState("");
  const [singerError, setSingerError] = useState("");
  const [licensorError, setLicensorError] = useState("");
  const [contentDescriptionError, setContentDescriptionError] = useState("");
  const [tagsError, setTagsError] = useState("");
  const [copyRightsError, setCopyRightsError] = useState("");
  const [publishingRightsError, setPublishingRightsError] = useState("");
  const [clickedButton, setClickedButton] = useState(null);

  const navigate = useNavigate();

  const today = new Date();
  const initialMinDate = new Date(today.setDate(today.getDate() + 7))
    .toISOString()
    .split("T")[0];
  const [minDate] = useState(initialMinDate);

  const handleNextClick = () => {
    const isVideoContent = contentType === "video";
    const hasErrors =
      !artistNameValue ||
      !artistLegalNameValue ||
      !trackNameValue ||
      !writerValue ||
      !directorValue ||
      !singerValue ||
      !licensorValue ||
      !contentDescriptionValue ||
      // !tagsValue ||
      !copyRightsValue ||
      !publishingRightsValue ||
      // !isrcValue ||
      !selectedSubGenres ||
      !selectedLanguages ||
      !selectedGenres ||
      !selectedMood ||
      !releaseDate ||
      isExplicit === "" ||
      (isVideoContent && (!selectedRating || !selectedKeywords));

    if (hasErrors) {
      alert("Please fill out all required fields.");
    } else {
      navigate("/content-distribution");
    }
  };

  const saveToLocalStorage = () => {
    const formData = {
      artistNameValue,
      artistLegalNameValue,
      trackNameValue,
      writerValue,
      directorValue,
      singerValue,
      licensorValue,
      contentDescriptionValue,
      tagsValue,
      copyRightsValue,
      publishingRightsValue,
      isrcValue,
      selectedSubGenres,
      selectedLanguages,
      selectedKeywords,
      isExplicit,
      selectedGenres,
      selectedMood,
      selectedRating,
      releaseDate,
    };
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  useEffect(() => {
    saveToLocalStorage();
  }, [
    artistNameValue,
    artistLegalNameValue,
    trackNameValue,
    writerValue,
    directorValue,
    singerValue,
    licensorValue,
    contentDescriptionValue,
    tagsValue,
    copyRightsValue,
    publishingRightsValue,
    isrcValue,
    selectedSubGenres,
    selectedLanguages,
    selectedKeywords,
    isExplicit,
    selectedGenres,
    selectedMood,
    selectedRating,
    releaseDate,
  ]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData"));
    if (savedData) {
      setArtistNameValue(savedData.artistNameValue || "");
      setArtistLegalNameValue(savedData.artistLegalNameValue || "");
      setTrackNameValue(savedData.trackNameValue || "");
      setWriterValue(savedData.writerValue || "");
      setDirectorValue(savedData.directorValue || "");
      setSingerValue(savedData.singerValue || "");
      setLicensorValue(savedData.licensorValue || "");
      setContentDescriptionValue(savedData.contentDescriptionValue || "");
      setTagsValue(savedData.tagsValue || "");
      setCopyRightsValue(savedData.copyRightsValue || "");
      setPublishingRightsValue(savedData.publishingRightsValue || "");
      setIsrcValue(savedData.isrcValue || "");
      setSelectedSubGenres(savedData.selectedSubGenres || []);
      setSelectedLanguages(savedData.selectedLanguages || []);
      setSelectedKeywords(savedData.selectedKeywords || []);
      setIsExplicit(savedData.isExplicit || {});
      setSelectedGenres(savedData.selectedGenres || []);
      setSelectedMood(savedData.selectedMood || "");
      setSelectedRating(savedData.selectedRating || "");
      setReleaseDate(savedData.releaseDate || "");
    }
  }, []);

  const handleArtistNameChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setArtistNameValue(value);
      setArtistNameError("");
    } else {
      setArtistNameError("Please Enter a valid name");
    }
    saveToLocalStorage();
  };

  const handleArtistLegalNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setArtistLegalNameValue(value);
      setArtistLegalNameError("");
    } else {
      setArtistLegalNameError("Please Enter a valid name");
    }
    saveToLocalStorage();
  };

  const handleISRCChange = (event) => {
    const value = event.target.value.toUpperCase();

    if (value.length <= 12) {
      setIsrcValue(value);

      const regex = /^[A-Z]{2}[A-Z0-9]{3}[0-9]{2}[0-9]{5}$/;
      if (!regex.test(value)) {
        setIsrcError("Please Enter a valid name");
      } else {
        setIsrcError("");
      }
    } else {
      setIsrcError("ISRC number should be exactly 12 characters.");
    }
    saveToLocalStorage();
  };

  const handleTrackNameChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setTrackNameValue(value);
      setTrackNameError("");
    } else {
      setTrackNameError("Value cannot exceed 100 characters.");
    }
    saveToLocalStorage();
  };

  const handleDirectorChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setDirectorValue(value);
      setDirectorError("");
    } else {
      setDirectorError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleWriterChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setWriterValue(value);
      setWriterError("");
    } else {
      setWriterError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleSingerChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setSingerValue(value);
      setSingerError("");
    } else {
      setSingerError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleLicensorChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setLicensorValue(value);
      setLicensorError("");
    } else {
      setLicensorError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleContentDescriptionChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setContentDescriptionValue(value);
      setContentDescriptionError("");
    } else {
      setContentDescriptionError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleTagsChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setTagsValue(value);
      setTagsError("");
    } else {
      setTagsError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handlePublishingRightsChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setPublishingRightsValue(value);
      setPublishingRightsError("");
    } else {
      setPublishingRightsError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleCopyRightsChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setCopyRightsValue(value);
      setCopyRightsError("");
    } else {
      setCopyRightsError("Value cannot exceed 200 characters.");
    }
    saveToLocalStorage();
  };

  const handleGenreToggle = (genre) => {
    setSelectedGenres((prev) => {
      const newGenres = prev.includes(genre)
        ? prev.filter((item) => item !== genre)
        : [...prev, genre];
      saveToLocalStorage(newGenres);
      return newGenres;
    });
  };

  const handleSubGenreToggle = (subGenre) => {
    setSelectedSubGenres((prev) => {
      const newSubGenres = prev.includes(subGenre)
        ? prev.filter((item) => item !== subGenre)
        : [...prev, subGenre];
      saveToLocalStorage(newSubGenres);
      return newSubGenres;
    });
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords((prev) => {
      const newKeyword = prev.includes(keyword)
        ? prev.filter((item) => item !== keyword)
        : [...prev, keyword];
      saveToLocalStorage(newKeyword);
      return newKeyword;
    });
  };

  const handleLanguageToggle = (language) => {
    setSelectedLanguages((prev) => {
      const newLanguages = prev.includes(language)
        ? prev.filter((item) => item !== language)
        : [...prev, language];
      saveToLocalStorage(newLanguages);
      return newLanguages;
    });
  };

  const handleMoodChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMood(selectedValue);
    saveToLocalStorage(selectedValue);
  };

  const handleRatingChange = (event) => {
    const selectedRatingValue = event.target.value;
    setSelectedRating(selectedRatingValue);
    saveToLocalStorage(selectedRatingValue);
  };

  const handleReleaseDateChange = (event) => {
    const selectedDate = event.target.value;
    setReleaseDate(selectedDate);
    saveToLocalStorage(selectedDate);
  };
  const handleYesClick = () => {
    setIsExplicit(1);
    setClickedButton("yes");
    saveToLocalStorage();
  };

  const handleNoClick = () => {
    setIsExplicit(0);
    setClickedButton("no");
    saveToLocalStorage();
  };

  //--------- API integration ---------\

  return (
    <>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div className="container1">
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md ">
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          {showIprs && (
            <>
              <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
                <p>IPRS</p>
              </div>
              <br />
            </>
          )}
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && <p>Attach Audio</p>}
            {contentType === "video" && <p>Attach Video</p>}
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] mx-0 w-[80%] flex-row"
            >
              Artist Name <span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="Artist Aloud"
              value={artistNameValue}
              onChange={handleArtistNameChange}
              required
            />
            <br />
            {/* {stringError && <span style={{ color: "red" }}>{stringError}</span>} */}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Album Name<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="Artist Aloud"
              value={artistLegalNameValue}
              onChange={handleArtistLegalNameChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Track Name<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder="Artist Aloud"
              value={trackNameValue}
              onChange={handleTrackNameChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row "
            >
              Language<span className="text-[#FF6C2F]">*</span>
            </label>
            <div className="rounded-[5px] bg-[#4c4c4c] w-[35rem] text-[#FFFFFF] font-[Poppins] p-[2%]">
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {[
                  "Hindi",
                  "Telugu",
                  "Tamil",
                  "Bengali",
                  "Gujarati",
                  "Malayalam",
                  "Odia",
                  "Urdu",
                  "Kannada",
                  "Sanskrit",
                  "Marathi",
                  "Assamese",
                  "Punjabi",
                  "Nepali",
                  "Konkani",
                  "Maithili",
                  "Dogri",
                  "Sindhi",
                  "Santali",
                  "Manipuri",
                  "Bhojpuri",
                  "Arabic",
                  "Meitei",
                  "Chattisgiri",
                  "Haryanvi",
                ].map((language) => (
                  <div key={language} className="flex items-center">
                    <input
                      type="checkbox"
                      id={language}
                      value={language}
                      checked={selectedLanguages.includes(language)}
                      onChange={() => handleLanguageToggle(language)}
                      className="mr-2"
                    />
                    <label htmlFor={language}>{language}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Genre<span className="text-[#FF6C2F]">*</span>
            </label>
            <div className="rounded-[5px] bg-[#4c4c4c] w-[35rem] text-[#FFFFFF] font-[Poppins] p-[2%]">
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {[
                  "Acoustic",
                  "Anthem",
                  "Bhajan",
                  "Bhangra",
                  "Bharatanatyam",
                  "Bhavageete",
                  "Blues",
                  "Carnatic music",
                  "Chaiti",
                  "Classical",
                  "Comedy",
                  "Contemperory",
                  "Country",
                  "Dadra",
                  "Devotional",
                  "Devotional song",
                  "Dhamar",
                  "Dhrupad",
                  "EDM",
                  "Filmi",
                  "Filmi qawwali",
                  "Folk music",
                  "Funk",
                  "Fusion",
                  "Gaana",
                  "Ghazal",
                  "Hard Rock",
                  "Haveli Sangeet",
                  "Hindi cinema",
                  "Hindustani classical music",
                  "Hip-Hop",
                  "Hori",
                  "Indian hip hop",
                  "Indian pop",
                  "Indian rock",
                  "Inspirational",
                  "Khyal",
                  "Light music",
                  "Mand",
                  "Music in ancient India",
                  "Music of Punjab",
                  "Music of Rajasthan",
                  "Odissi music",
                  "Patriotic",
                  "Poetry",
                  "Pop",
                  "Qawwali",
                  "R&B",
                  "Rabindra Sangeet",
                  "Raga",
                  "Raga rock",
                  "Rap",
                  "Rock",
                  "Romantic",
                  "Sadra",
                  "Semi-classical music",
                  "Sufi",
                  "Sufi music",
                  "Sufi rock",
                  "Sugama Sangeetha",
                  "Tappa",
                  "Tarana",
                  "Thumri",
                  "Trance",
                  "Trap",
                  "World music",
                ].map((genre) => (
                  <div key={genre} className="flex items-center">
                    <input
                      type="checkbox"
                      id={genre}
                      value={genre}
                      checked={selectedGenres.includes(genre)}
                      onChange={() => handleGenreToggle(genre)}
                      className="mr-2"
                    />
                    <label htmlFor={genre}>{genre}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Sub-Genre<span className="text-[#FF6C2F]">*</span>
            </label>
            <div className="rounded-[5px] bg-[#4c4c4c] w-[35rem] text-[#FFFFFF] font-[Poppins] p-[2%]">
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {[
                  "Aarti",
                  "Abhang",
                  "Acoustic",
                  "Acoustic Pop",
                  "Alternative",
                  "Alternative Metal",
                  "Alternative Rock",
                  "Anthem",
                  "Ashaar",
                  "Ballad",
                  "Bhajan",
                  "Bhakti Sangeet",
                  "Bheem Geet",
                  "Blues",
                  "Bollywood",
                  "Carnatic",
                  "Carols",
                  "Chants",
                  "Children",
                  "Christian",
                  "Classic Rock",
                  "Classical",
                  "Comedy",
                  "Contemporary",
                  "Country",
                  "Dance",
                  "Dance Pop",
                  "Devotional",
                  "Dharmik Sangeet",
                  "Disco",
                  "Discourse",
                  "Dohe",
                  "Dua",
                  "Dubstep",
                  "Easy Listening",
                  "EDM",
                  "Electro",
                  "Electro House",
                  "Electronic Trap",
                  "Electronica",
                  "Experimental",
                  "Festival",
                  "Folk",
                  "Folk Rock",
                  "Funk",
                  "Funny",
                  "Fusion",
                  "Ghazal",
                  "Gospel",
                  "Grunge",
                  "Guru Bani",
                  "Hadith Sharif",
                  "Hamd",
                  "Hard Rock",
                  "Hindustani",
                  "Hip hop",
                  "Hollywood",
                  "House",
                  "Hymns",
                  "Indie",
                  "Indie Pop",
                  "Indie Rock",
                  "Inspirational",
                  "Interview",
                  "Islamic",
                  "Jazz",
                  "Kirtan",
                  "Lavani",
                  "Lokgeet",
                  "Lounge",
                  "Mantras",
                  "Meditation",
                  "Mersia",
                  "Mixtape",
                  "Motivational",
                  "Movie Soundtracks",
                  "Mushaira",
                  "Naad",
                  "Naats",
                  "Narration",
                  "Natak",
                  "New Age",
                  "Nonstop",
                  "Novelty",
                  "Oldies",
                  "Patriotic",
                  "Peaceful",
                  "Pop",
                  "Qawwali",
                  "Rap",
                  "Reggae",
                  "Remix",
                  "Rock",
                  "Romantic",
                  "Sanskrit",
                  "Sufi",
                  "Sound Effects",
                  "Speech",
                  "Spiritual",
                  "Sports",
                  "Storytelling",
                  "Talas",
                  "Talk",
                  "Techno",
                  "Themed Music",
                  "Traditional",
                  "Trance",
                  "Vedic",
                  "Wedding",
                  "World",
                ].map((subGenre) => (
                  <div key={subGenre} className="flex items-center">
                    <input
                      type="checkbox"
                      id={subGenre}
                      value={subGenre}
                      checked={selectedSubGenres.includes(subGenre)}
                      onChange={() => handleSubGenreToggle(subGenre)}
                      className="mr-2"
                    />
                    <label htmlFor={subGenre}>{subGenre}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Mood<span className="text-[#FF6C2F]">*</span>
            </label>
            <select
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] p-[2%]"
              style={{ outlineStyle: "none" }}
              value={selectedMood}
              onChange={handleMoodChange}
            >
              {[
                "Select Mood",
                "Happy",
                "Sad",
                "Exuberant",
                "Energetic",
                "Frantic",
                "Anxious",
                "Calm",
                "Contentment",
              ].map((mood) => (
                <option key={mood} value={mood}>
                  {mood}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Lyricist/Writer<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={writerValue}
              onChange={handleWriterChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Music Director/Director<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={directorValue}
              onChange={handleDirectorChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Singer/Artist<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={singerValue}
              onChange={handleSingerChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Licensor<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={licensorValue}
              onChange={handleLicensorChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Content Description<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={contentDescriptionValue}
              onChange={handleContentDescriptionChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Tags
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={tagsValue}
              onChange={handleTagsChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              ISRC
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={isrcValue}
              onChange={handleISRCChange}
              required
            />
          </div>
          <div>
            {isrcError && <span style={{ color: "red" }}>{isrcError}</span>}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Copy rights<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={copyRightsValue}
              onChange={handleCopyRightsChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Publishing Rights<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem]"
              placeholder=""
              value={publishingRightsValue}
              onChange={handlePublishingRightsChange}
              required
            />
          </div>

          {contentType === "video" && (
            <>
              <div>
                <label
                  htmlFor=""
                  className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
                >
                  Keywords<span className="text-[#FF6C2F]">*</span>
                </label>
                <div className="rounded-[5px] bg-[#4c4c4c] w-[35rem] text-[#FFFFFF] font-[Poppins] p-[2%]">
                  <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                    {[
                      "Themes and messages",
                      "Violence",
                      "Nudity",
                      "Sex",
                      "Language",
                      "Drug and substance abuse",
                      "Horror",
                    ].map((keyword) => (
                      <div key={keyword} className="flex items-center">
                        <input
                          type="checkbox"
                          id={keyword}
                          value={keyword}
                          checked={selectedKeywords.includes(keyword)}
                          onChange={() => handleKeywordToggle(keyword)}
                          className="mr-2"
                        />
                        <label htmlFor={keyword}>{keyword}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
                >
                  Rating<span className="text-[#FF6C2F]">*</span>
                </label>
                <select
                  className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] p-[2%]"
                  style={{ outlineStyle: "none" }}
                  value={selectedRating}
                  onChange={handleRatingChange}
                >
                  {/* <option
                    className="hover:bg-[#FF6C2F] active:bg-[#c75525]"
                    value="U"
                  >
                    U
                  </option>
                  <option value="7+">7+</option>
                  <option value="13+">13+</option>
                  <option value="16+">16+</option>
                  <option value="18+">18+</option> */}
                  {["Select Rating", "U", "7+", "13+", "16+", "18+"].map(
                    (rating) => (
                      <option key={rating} value={rating}>
                        {rating}
                      </option>
                    )
                  )}
                </select>
              </div>
            </>
          )}
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Explicit Content<span className="text-[#FF6C2F]">*</span>
            </label>
            <div className="flex justify-start">
              <button
                className={`w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] hover:bg-[#FF6C2F] active:bg-[#FF6C2F] ${
                  clickedButton === "yes" ? "bg-[#FF6C2F]" : ""
                }`}
                onClick={handleYesClick}
              >
                Yes
              </button>
              <button
                className={`w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#FF6C2F] active:bg-[#FF6C2F] ${
                  clickedButton === "no" ? "bg-[#FF6C2F]" : ""
                }`}
                onClick={handleNoClick}
              >
                No
              </button>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Release Date<span className="text-[#FF6C2F]">*</span>
            </label>
            <input
              type="date"
              className="rounded-[5px] bg-[#4c4c4c] w-[15rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5"
              placeholder=""
              value={releaseDate}
              onChange={handleReleaseDateChange}
              required
              min={minDate}
            />
          </div>
          <div className="flex justify-end mb-[10%]">
            <button
              className="mt-[8%] text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Details;
