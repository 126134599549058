import { Navigate, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar2 from "../components/Navbar2";
import { publicRouteLink } from "../constant/Routes";
import axios from "axios";

const PrivateRoutes = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("Token");

    if (token) {
      axios
        .get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/checkToken/${token}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response?.data?.status === 200) {
            setIsVerified(true); // Token is valid
          } else {
            sessionStorage.removeItem("Token"); // Clear invalid token
            setIsVerified(false);
            navigate(publicRouteLink.SIGNIN); // Redirect to sign-in
          }
        })
        .catch((error) => {
          console.error("Token validation error:", error.response?.data);
          sessionStorage.removeItem("Token"); // Clear token on error
          setIsVerified(false);
          navigate(publicRouteLink.SIGNIN); // Redirect to sign-in
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate(publicRouteLink.SIGNIN); // Redirect if no token
      setLoading(false);
    }
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // Optional loading state
  }

  return isVerified ? (
    <>
      <Navbar2 />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate to={publicRouteLink.SIGNIN} replace />
  );
};

export default PrivateRoutes;
