import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useContent } from "../context/main.js";
import axios from "axios";
function ProfilePicture(props) {
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState("");
  const [artistName, setArtistName] = useState("");
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const { formData, subscriptionPlan } = useContent();

  // Get the token from sessionStorage (it should be stored when the user logs in)
  const token = sessionStorage.getItem("Token");

  useEffect(() => {
    setImageError("");
  }, [image]);
  // Fetch the profile image when the component mounts
  useEffect(() => {
    if (token) {
      const fetchProfileImage = async () => {
        try {
          const response = await fetch(
            `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`,
            {
              method: "GET",
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch profile image");
          }

          const data = await response.json();
          const userData = data?.data[0]; // Extract the first object from the array

          if (userData?.profile_image) {
            console.log(
              "Profile Image URL from GET API:",
              userData.profile_image
            ); // Log the URL here
            setImage(userData.profile_image);
            // setArtistName(userData.full_name || "Your Name");
          }
        } catch (error) {
          console.error("Error fetching profile image:", error);
        }
      };

      fetchProfileImage();
    }
  }, [token, isImageUpdated]);

  useEffect(() => {
    const getUserInfo = async () => {
      const token = sessionStorage.getItem("Token");
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
        const userData = response.data.data[0];

        // Log the API response
        console.log("API response data:", userData);

        setArtistName(userData.full_name || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, []);

  // Handle image upload
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file.name.includes(" ")) {
      setImageError("File name should not contain spaces.");
      return; // Prevent the upload if the file name has spaces
    }

    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);

      try {
        const apiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v3/profileImage/${token}`;

        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Failed to upload image");
        }

        const data = await response.json();
        const uploadedImageUrl = data?.data?.profileImageUrl;

        console.log("Uploaded image URL:", uploadedImageUrl);

        if (uploadedImageUrl) {
          setImage(`${uploadedImageUrl}?timestamp=${new Date().getTime()}`); // Cache-busting
          setIsImageUpdated(true); // Prevent useEffect from overwriting
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Failed to upload profile image.");
      }
    }
  };

  const getTextColor = () => {
    switch (subscriptionPlan) {
      case "platinum":
        return "bg-platinum"; // Applies the platinum gradient
      case "gold":
        return "bg-gold"; // Applies the gold gradient
      case "silver":
        return "bg-silver"; // Applies the silver gradient
      default:
        return "bg-free"; // Applies the free plan style (gray text)
    }
  };

  return (
    <div className="profile_bar">
      <div
        className="image-uploader"
        style={{
          width: "200px",
          height: "200px",
          marginLeft: "15%",
          marginTop: "15%",
          borderRadius: "10px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          overflow: "hidden",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          border: "2px solid #ff6c2f",
        }}
      >
        {/* Hidden file input */}
        <input
          type="file"
          id="upload_ProfilePicture"
          name="file"
          accept=".jpeg,.jpg,.png"
          onChange={handleImageChange}
          hidden
        />

        {/* Upload Button */}
        <label
          htmlFor="upload_ProfilePicture"
          style={{
            backgroundColor: "#ff6c2f",
            padding: "8px 12px",
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "6px",
            fontSize: "12px",
            margin: "10px",
          }}
        >
          <img
            src="./assets/imgUpload.png"
            alt="Upload Icon"
            style={{
              width: "15px",
              height: "15px",
            }}
          />
          Edit
        </label>
      </div>
      <div className="px-[15%] pb-[5%] text-[12px] h-[5%] mb-[2%]">
        {imageError && <p className="text-red-500">{imageError}</p>}
      </div>
      <div>
        <h2 className="text-[#FFFFFF] ml-[15%] text-[1.4rem] mt-[5%] flex items-center">
          <span className="mr-2  rounded-full  flex justify-center items-center cursor-pointer transform transition-transform duration-300 hover:scale-110">
          🎤
          </span>
          {artistName}
        </h2>
      </div>
      {/* <div className="flex items-center justify-center mt-[15%] w-full">
        <div
          className={`subscription-text ${getTextColor()} text-2xl font-bold`}
        >
          {subscriptionPlan?.toUpperCase() || ""}
        </div>
      </div> */}

      <ToastContainer />
    </div>
  );
}

export default ProfilePicture;
