import React from "react";
import { useNavigate } from "react-router-dom";

const Notifications = ({ notifications }) => {
  const navigate = useNavigate();
  if (!notifications || notifications.length === 0) {
    return <div>No new notifications.</div>;
  }

  const handleViewAll = () => {
    navigate("/upload");
  };

  return (
    <div
      className="px-6 pt-1 pb-5 w-full text-white rounded-lg max-md:px-5 max-md:max-w-full bg-[#252525]"
      style={{ padding: "10px", maxHeight: "584px", overflowY: "auto" }}
    >
      <ul>
        {notifications.map((notification, index) => (
          <li
            key={index}
            style={{ padding: "10px 10px", borderBottom: "1px solid #ddd" }}
            className="flex flex-col w-full items-start py-2 ${item.bgColor} bg-opacity-0 max-md:pr-5 max-md:max-w-full"
          >
            {notification}
          </li>
        ))}
      </ul>
      <div className="flex justify-end">
        {/* <button
          className="bg-[#FF6C2F] text-black w-24 mt-6 rounded-md h-10"
          onClick={handleViewAll}
        >
          View All
        </button> */}
      </div>
    </div>
  );
};

export default Notifications;
