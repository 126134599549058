import React, { useState, useEffect } from "react";

export default function TakeDownPopup({
  isOpen,
  onClose,
  songDetails,
  contentIdForSong,
}) {
  const [selectedSong, setSelectedSong] = useState(false);
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");

  const handleReasonChange = (event) => setReason(event.target.value);
  const handleDetailsChange = (event) => setDetails(event.target.value);

  useEffect(() => {
    console.log("Received songDetails:", songDetails); // Debugging
    console.log("Received contentIdForSong:", contentIdForSong);
  }, [songDetails, contentIdForSong]);

  const handleOk = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#252525] w-[90%] sm:w-[40%] rounded-lg p-6 shadow-lg">
        <h2 className="text-white text-xl font-semibold mb-4">
          Take Down Song
        </h2>
        <div className="flex items-center space-x-4 mb-4">
          <input
            type="checkbox"
            id="songCheckbox"
            className="w-4 h-4 text-white border-gray-300 rounded"
            checked={selectedSong}
            onChange={() => setSelectedSong(!selectedSong)}
          />
          <label htmlFor="songCheckbox" className="text-white">
            {songDetails || "Song Name"}
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="reason" className="block text-white font-medium mb-2">
            Reason for Takedown
          </label>
          <select
            id="reason"
            className="w-full text-white bg-[#252525] p-2 border border-gray-300 rounded-lg"
            value={reason}
            onChange={handleReasonChange}
          >
            <option value="">Select a reason</option>
            <option value="Rights disputes or ownership conflicts">
              Rights disputes or ownership conflicts
            </option>
            <option value="Expired or terminated contracts">
              Expired or terminated contracts
            </option>
            <option value="Desire to update or improve song quality">
              Desire to update or improve song quality
            </option>
            <option value="Rebranding or change in artist identity">
              Rebranding or change in artist identity
            </option>
            <option value="Platform exclusivity agreements">
              Platform exclusivity agreements
            </option>
            <option value="Legal claims or copyright infringement issues">
              Legal claims or copyright infringement issues
            </option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="details"
            className="block text-white font-medium mb-2"
          >
            Additional Details
          </label>
          <textarea
            id="details"
            rows="3"
            className="w-full p-2 border border-gray-300 rounded-lg bg-[#252525] text-white"
            placeholder="Explain your reason..."
            value={details}
            onChange={handleDetailsChange}
          ></textarea>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#FF6C2F] text-white rounded-lg cursor-pointer hover:bg-[#FF6C2F] disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={handleOk}
            disabled={!selectedSong || !reason}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
