import React from 'react';
import '../App.css'; // Optional: For styling

const ResponsivePopup = ({ message}) => {
  return (
    <div className="popup-overlay-responsive">
      <div className="popup-content-responsive">
        <h2 className='popup-message-responsive'>{message}</h2>
      </div>
    </div>
  );
};

export default ResponsivePopup;
