import React, { useState, useEffect } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

export default function CoverImage() {
  const { contentType, showIprs } = useContent();
  const navigate = useNavigate();
  const { file, setFile } = useContent();
  const [preview, setPreview] = useState("");


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(selectedFile.type)) {
        alert("Invalid file type. Please select a JPG or PNG image.");
        setPreview("");
        setFile(null);
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result;
        img.onload = () => {
          if (img.width === 3000 && img.height === 3000) {
            setPreview(reader.result);
            setFile(selectedFile);
          } else {
            toast.error("Image dimensions must be 3000x3000 pixels.");
            setPreview("");
            setFile(null);
          }
        };
        img.onerror = () => {
          alert.error("Error loading image.");
          setPreview("");
          setFile(null);
        };
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  // const handleAudioSubmitClick = async () => {
  //   if (!file) {
  //     alert("Please upload both audio and image files before proceeding.");
  //     return;
  //   }

  //   const token = sessionStorage.getItem("Token");

  //   // Retrieve the stored file names from localStorage
  //   const uploadedAudioFileName = localStorage.getItem("uploadedAudioFile");
  //   const uploadedImageFileName = localStorage.getItem("uploadedImage");

  //   // Retrieve other form data from localStorage
  //   const formData = JSON.parse(localStorage.getItem("formData")) || {};
  //   const platformSelection =
  //     JSON.parse(localStorage.getItem("platformSelection")) || {};
  //   const signature = localStorage.getItem("signature") || "";
  //   const signatureIprs = localStorage.getItem("signatureIprs") || "";

  //   // Create FormData object
  //   const formPayload = new FormData();

  //   // Append form data fields
  //   formPayload.append("artist_name", formData.artistNameValue || "");
  //   formPayload.append("track_name", formData.trackNameValue || "");
  //   formPayload.append(
  //     "language",
  //     JSON.stringify(formData.selectedLanguages || [])
  //   );
  //   formPayload.append("genre", JSON.stringify(formData.selectedGenres || []));
  //   formPayload.append(
  //     "sub_genre",
  //     JSON.stringify(formData.selectedSubGenres || [])
  //   );
  //   formPayload.append("music_director", formData.directorValue || "");
  //   formPayload.append("singer", formData.singerValue || "");
  //   formPayload.append("mood", formData.selectedMood || "");
  //   formPayload.append("licensor", formData.licensorValue || "");
  //   formPayload.append(
  //     "content_description",
  //     formData.contentDescriptionValue || ""
  //   );
  //   formPayload.append("tags", formData.tagsValue || "");
  //   formPayload.append("isrc", formData.isrcValue || "");
  //   formPayload.append("copy_rights", formData.copyRightsValue || "");
  //   formPayload.append(
  //     "publishing_right",
  //     formData.publishingRightsValue || ""
  //   );
  //   formPayload.append("explicit_content", formData.isExplicit || 0);
  //   formPayload.append("release_date", formData.releaseDate || "");
  //   formPayload.append(
  //     "platforms",
  //     JSON.stringify(platformSelection.platforms || [])
  //   );
  //   formPayload.append("is_iprs_member", formData.isIprsMember || "");
  //   formPayload.append(
  //     "distribution_type",
  //     platformSelection.distributionType || ""
  //   );
  //   formPayload.append("digitalSign", signature);
  //   formPayload.append("iprsDigitalSign", signatureIprs);

  //   //add contentId which is stored in session storage

  //   // Append the audio file
  //   const uploadedAudioFile = localStorage.getItem("uploadedAudioFile");
  //   if (uploadedAudioFile) {
  //     const audioFile = new File([uploadedAudioFile], uploadedAudioFileName, {
  //       type: "audio/wav",
  //     });
  //     formPayload.append("audio", audioFile);
  //   }

  //   // Append the image file
  //   const uploadedImageFile = localStorage.getItem("uploadedImage");
  //   if (uploadedImageFile) {
  //     const imageFile = new File([uploadedImageFile], uploadedImageFileName, {
  //       type: "image/png",
  //     });
  //     formPayload.append("artwork", imageFile);
  //   }

  //   try {
  //     const apiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/audio/${token}`;
  //     const response = await axios.post(apiUrl, formPayload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     console.log("Server Response:", response);
  //     if (response.status === 200) {
  //       alert("Data submitted successfully!");
  //       navigate("/upload-submission");
  //     } else {
  //       alert("Failed to submit data.");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting data:", error);
  //     alert("An error occurred while submitting the data.");
  //     if (error.response) {
  //       console.log("Error Response Data:", error.response.data);
  //     } else {
  //       console.log("Error Message:", error.message);
  //     }
  //   }
  // };

  const handleAudioSubmitClick = async () => {
    if (!file) {
      toast.error(
        "Please upload both audio and image files before proceeding."
      );
      return;
    }

    const token = sessionStorage.getItem("Token");

    // Retrieve other form data from localStorage
    const formData = JSON.parse(localStorage.getItem("formData")) || {};
    const platformSelection =
      JSON.parse(localStorage.getItem("platformSelection")) || {};
    // const signature = localStorage.getItem("signature") || "";
    // const signatureIprs = localStorage.getItem("signatureIprs") || "";

    // Create FormData object for audio upload
    const formPayload = new FormData();

    // Append form data fields for audio
    formPayload.append("artist_name", formData.artistNameValue || "");
    formPayload.append(
      "artist_legal_name",
      formData.artistLegalNameValue || ""
    );
    formPayload.append("track_name", formData.trackNameValue || "");
    formPayload.append(
      "language",
      JSON.stringify(formData.selectedLanguages || [])
    );
    formPayload.append("genre", JSON.stringify(formData.selectedGenres || []));
    formPayload.append(
      "sub_genre",
      JSON.stringify(formData.selectedSubGenres || [])
    );
    formPayload.append("music_director", formData.directorValue || "");
    formPayload.append("singer", formData.singerValue || "");
    formPayload.append("mood", formData.selectedMood || "");
    formPayload.append("licensor", formData.licensorValue || "");
    formPayload.append(
      "content_description",
      formData.contentDescriptionValue || ""
    );
    formPayload.append("tags", formData.tagsValue || "");
    formPayload.append("isrc", formData.isrcValue || "");
    formPayload.append("copy_rights", formData.copyRightsValue || "");
    formPayload.append(
      "publishing_right",
      formData.publishingRightsValue || ""
    );
    formPayload.append("explicit_content", formData.isExplicit || 0);
    formPayload.append("release_date", formData.releaseDate || "");
    formPayload.append(
      "platforms",
      JSON.stringify(platformSelection.platforms || [])
    );
    formPayload.append("is_iprs_member", formData.isIprsMember || "");
    formPayload.append(
      "distribution_type",
      platformSelection.distributionType || ""
    );
    // formPayload.append("digitalSign", signature);
    formPayload.append("writer", formData.writerValue || "");
    // formPayload.append("iprsDigitalSign", signatureIprs);

    // Add contentId to form payload (retrieved from sessionStorage)
    const contentId = localStorage.getItem("contentId");
    formPayload.append("contentId", contentId);

    // API for audio upload (PUT)
    try {
      const audioApiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/audio/${token}`;
      const audioResponse = await axios.put(audioApiUrl, formPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Audio Upload Response:", audioResponse);
      if (audioResponse.status === 200) {
        toast.success("Audio data submitted successfully!");
      } else {
        toast.error("Failed to submit audio data.");
      }
    } catch (error) {
      console.error("Error submitting audio data:", error);
      alert("An error occurred while submitting the audio data.");
      if (error.response) {
        console.log("Error Response Data:", error.response.data);
      } else {
        console.log("Error Message:", error.message);
      }
    }

    // API for image upload (PATCH)
    try {
      const imageApiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/uploadImageThumbnail/${token}`;
      const imageFormData = new FormData();

      // Append the contentId and the image file (if selected)
      if (file) {
        imageFormData.append("imageThumbnail", file);
      }
      imageFormData.append("contentId", contentId);

      const imageResponse = await axios.patch(imageApiUrl, imageFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Image Upload Response:", imageResponse);
      if (imageResponse.status === 201) {
        navigate("/upload-submission");
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error submitting image data:", error);
      if (error.response) {
        console.log("Error Response Data:", error.response.data);
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const handleVideoSubmitClick = async () => {
    if (!file) {
      alert("Please upload both video and image files before proceeding.");
      return;
    }

    const token = sessionStorage.getItem("Token");

    // Retrieve other form data from localStorage
    const formData = JSON.parse(localStorage.getItem("formData")) || {};
    const platformSelection =
      JSON.parse(localStorage.getItem("platformSelection")) || {};
    // const signature = localStorage.getItem("signature") || "";
    // const signatureIprs = localStorage.getItem("signatureIprs") || "";

    // Create FormData object for video upload
    const formPayload = new FormData();

    // Append form data fields for video
    formPayload.append("artist_name", formData.artistNameValue || "");
    formPayload.append(
      "artist_legal_name",
      formData.artistLegalNameValue || ""
    );
    formPayload.append("track_name", formData.trackNameValue || "");
    formPayload.append(
      "language",
      JSON.stringify(formData.selectedLanguages || [])
    );
    formPayload.append("genre", JSON.stringify(formData.selectedGenres || []));
    formPayload.append(
      "sub_genre",
      JSON.stringify(formData.selectedSubGenres || [])
    );
    formPayload.append("music_director", formData.directorValue || "");
    formPayload.append("singer", formData.singerValue || "");
    formPayload.append("mood", formData.selectedMood || "");
    formPayload.append("licensor", formData.licensorValue || "");
    formPayload.append(
      "content_description",
      formData.contentDescriptionValue || ""
    );
    formPayload.append("tags", formData.tagsValue || "");
    formPayload.append("isrc", formData.isrcValue || "");
    formPayload.append("copy_rights", formData.copyRightsValue || "");
    formPayload.append(
      "publishing_right",
      formData.publishingRightsValue || ""
    );
    formPayload.append("explicit_content", formData.isExplicit || 0);
    formPayload.append("release_date", formData.releaseDate || "");
    formPayload.append(
      "platforms",
      JSON.stringify(platformSelection.platforms || [])
    );
    formPayload.append("is_iprs_member", formData.isIprsMember || "");
    formPayload.append(
      "distribution_type",
      platformSelection.distributionType || ""
    );
    // formPayload.append("digitalSign", signature);
    formPayload.append("rating", formData.selectedRating || "");
    formPayload.append(
      "keywords",
      JSON.stringify(formData.selectedKeywords || [])
    );
    formPayload.append("writer", formData.writerValue || "");
    // formPayload.append("iprsDigitalSign", signatureIprs);

    // Add contentId to form payload (retrieved from sessionStorage)
    const contentId = localStorage.getItem("contentId");
    formPayload.append("contentId", contentId);

    // API for video upload (PUT)
    try {
      const videoApiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/video/${token}`;
      const videoResponse = await axios.put(videoApiUrl, formPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Video Upload Response:", videoResponse);
      if (videoResponse.status === 200) {
        toast.success("Video data submitted successfully!");
      } else {
        toast.error("Failed to submit video data.");
      }
    } catch (error) {
      console.error("Error submitting video data:", error);
      toast.error("An error occurred while submitting the video data.");
      if (error.response) {
        console.log("Error Response Data:", error.response.data);
      } else {
        console.log("Error Message:", error.message);
      }
    }

    // API for image upload (PATCH)
    try {
      const imageApiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/uploadImageThumbnail/${token}`;
      const imageFormData = new FormData();

      // Append the contentId and the image file (if selected)
      if (file) {
        imageFormData.append("imageThumbnail", file);
      }
      imageFormData.append("contentId", contentId);

      const imageResponse = await axios.patch(imageApiUrl, imageFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Image Upload Response:", imageResponse);
      if (imageResponse.status === 201) {
        navigate("/upload-submission");
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error submitting image data:", error);
      alert("An error occurred while submitting the image data.");
      if (error.response) {
        console.log("Error Response Data:", error.response.data);
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          {showIprs && (
            <>
              <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
                <p>IPRS</p>
              </div>
              <br />
            </>
          )}
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && <p>Attach Audio</p>}
            {contentType === "video" && <p>Attach Video</p>}
          </div>
          <br />
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className=" mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%] flex flex-row justify-between">
            <div className="flex flex-col justify-between">
              <div>
                {contentType === "audio" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Artwork
                  </h2>
                )}
                {contentType === "video" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Thumbnail
                  </h2>
                )}
              </div>
              <div>
                <button className="w-[8rem] h-[2.3rem] text-[#FFFFFF] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[7%]">
                  <label htmlFor="file-upload">Choose File</label>
                </button>
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".jpeg,.jpg,.png"
                />
              </div>
            </div>
            <div>
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-[35rem] rounded-md my-4">
            {contentType === "audio" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-col gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-row gap-2">
                  <div className="w-[50%] flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>Artwork Dimension should be</span>
                    <div className="flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        className="w-[50px] h-[40px] m-0 shrink-0"
                        style={{
                          shapeRendering: "geometricPrecision",
                          textRendering: "geometricPrecision",
                          imageRendering: "optimizeQuality",
                          fillRule: "evenodd",
                          clipRule: "evenodd",
                        }}
                        viewBox="0 0 6.827 6.827"
                      >
                        <defs>
                          <style>{".fil0{fill:none}"}</style>
                        </defs>
                        <g id="Layer_x0020_1">
                          <g id="_371355568">
                            <path
                              id="_371356744"
                              className="fil0"
                              d="M0 0h6.827v6.827H0z"
                            />
                            <path
                              id="_371355616"
                              className="fil0"
                              d="M.853.853h5.12v5.12H.853z"
                            />
                          </g>
                          <path
                            d="M1.137.853H5.69a.283.283 0 0 1 .284.284V5.69a.283.283 0 0 1-.284.284H1.137a.283.283 0 0 1-.284-.284V1.137a.283.283 0 0 1 .284-.284zm4.552.214H1.137a.07.07 0 0 0-.05.02.07.07 0 0 0-.02.05V5.69c0 .02.008.037.02.05a.07.07 0 0 0 .05.021H5.69a.07.07 0 0 0 .05-.02.07.07 0 0 0 .021-.05V1.136a.07.07 0 0 0-.02-.05.07.07 0 0 0-.05-.02z"
                            style={{ fill: "#ffffff", fillRule: "nonzero" }}
                          />
                          <path
                            style={{ fill: "#FF6C2F", fillRule: "nonzero" }}
                            d="m1.173 3.356.325-.325.075-.075.076.075.538.538.538-.538.075-.075.075.075.538.538.538-.538.076-.075.075.075.538.538.538-.538.075-.075.076.075.324.325v.302l-.4-.4-.538.537-.075.076-.075-.076-.538-.538-.538.538-.076.076-.075-.076-.538-.538-.538.538-.075.076-.076-.076-.538-.538-.4.4z"
                          />
                        </g>
                      </svg>
                      <span className="text-[#FF6C2F] text-[1rem] font-semibold">
                        3000x3000 px
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        add title{" "}
                      </span>
                      on the{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        artwork{" "}
                      </span>{" "}
                      in sync with the track name.
                    </span>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure there are no </span>
                    <span className="text-[#FF2F2F] text-[0.8rem]">
                      social media handles{" "}
                      <span className="text-slate-200">, </span>music platforms
                      logos <span className="text-slate-200"> and </span>{" "}
                      personal <span className="text-slate-200"> or </span>{" "}
                      office contact details.
                    </span>{" "}
                  </div>
                  <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                    <span>File Format</span>
                    <span className="text-[#FF6C2F] text-[1.2rem] font-semibold">
                      .JPEG/.PNG
                    </span>
                  </div>
                </div>
              </div>
            )}

            {contentType === "video" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-col gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-row gap-2">
                  <div className="w-[50%] flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>Artwork Dimension should be</span>
                    <div className="flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        className="w-[50px] h-[40px] m-0 shrink-0"
                        style={{
                          shapeRendering: "geometricPrecision",
                          textRendering: "geometricPrecision",
                          imageRendering: "optimizeQuality",
                          fillRule: "evenodd",
                          clipRule: "evenodd",
                        }}
                        viewBox="0 0 6.827 6.827"
                      >
                        <defs>
                          <style>{".fil0{fill:none}"}</style>
                        </defs>
                        <g id="Layer_x0020_1">
                          <g id="_371355568">
                            <path
                              id="_371356744"
                              className="fil0"
                              d="M0 0h6.827v6.827H0z"
                            />
                            <path
                              id="_371355616"
                              className="fil0"
                              d="M.853.853h5.12v5.12H.853z"
                            />
                          </g>
                          <path
                            d="M1.137.853H5.69a.283.283 0 0 1 .284.284V5.69a.283.283 0 0 1-.284.284H1.137a.283.283 0 0 1-.284-.284V1.137a.283.283 0 0 1 .284-.284zm4.552.214H1.137a.07.07 0 0 0-.05.02.07.07 0 0 0-.02.05V5.69c0 .02.008.037.02.05a.07.07 0 0 0 .05.021H5.69a.07.07 0 0 0 .05-.02.07.07 0 0 0 .021-.05V1.136a.07.07 0 0 0-.02-.05.07.07 0 0 0-.05-.02z"
                            style={{ fill: "#ffffff", fillRule: "nonzero" }}
                          />
                          <path
                            style={{ fill: "#FF6C2F", fillRule: "nonzero" }}
                            d="m1.173 3.356.325-.325.075-.075.076.075.538.538.538-.538.075-.075.075.075.538.538.538-.538.076-.075.075.075.538.538.538-.538.075-.075.076.075.324.325v.302l-.4-.4-.538.537-.075.076-.075-.076-.538-.538-.538.538-.076.076-.075-.076-.538-.538-.538.538-.075.076-.076-.076-.538-.538-.4.4z"
                          />
                        </g>
                      </svg>
                      <span className="text-[#FF6C2F] text-[1rem] font-semibold">
                        3000x3000 px
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        add title{" "}
                      </span>
                      on the{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        artwork{" "}
                      </span>{" "}
                      in sync with the track name.
                    </span>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure there are no </span>
                    <span className="text-[#FF2F2F] text-[0.8rem]">
                      social media handles{" "}
                      <span className="text-slate-200">, </span>music platforms
                      logos <span className="text-slate-200"> and </span>{" "}
                      personal <span className="text-slate-200"> or </span>{" "}
                      office contact details.
                    </span>{" "}
                  </div>
                  <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                    <span>File Format</span>
                    <span className="text-[#FF6C2F] text-[1.2rem] font-semibold">
                      .JPEG/.PNG
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {contentType === "audio" && (
            <div
              className="mb-[5%] w-[80%]"
              style={{
                flexGrow: "8",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <button
                className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                style={{
                  fontFamily: "Poppins, sanSerif",
                }}
                onClick={handleAudioSubmitClick}
              >
                Submit
              </button>
            </div>
          )}
          {contentType === "video" && (
            <div
              className="mb-[5%] w-[80%]"
              style={{
                flexGrow: "8",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <button
                className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                style={{
                  fontFamily: "Poppins, sanSerif",
                }}
                onClick={handleVideoSubmitClick}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
