import React from 'react';

const IndependentTogether = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      {/* Logo Section */}
      <div style={{ backgroundColor: '#f4f4f4', padding: '10px 20px' }}>
        <img
          src="/assets/HungamaLogo.png" // Adjust the path to your logo file
          alt="Independent Together Logo"
          style={{ maxHeight: '80px' }}
        />
      </div>

      {/* Iframe Section */}
      <iframe
        src="https://tickets.hungamaartistaloud.com/reg-events/gjdgye-independent-together"
        style={{ width: '100%', height: '90vh', border: 'none' }}
        title="Independent Together"
      ></iframe>
    </div>
  );
};

export default IndependentTogether;
